import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
	Accordion, Button, ButtonType,
	IAccordionClassNames, Icon, IconSize,
	Modal,
	PageContainer,
	PageHeader,
	SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";
import styles from "@/pages/ServicePageView/ServicePageView.module.scss";
import {LoadingSpinner} from "@/components/LoadingSpinner/LoadingSpinner";
import moment from "moment-timezone";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
import {IDoctor} from "@/models/interfaces/IDoctor";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import {IAppointmentBookingParams} from "@/pages/AppointmentBooking/AppointmentBooking";
import {
	AppointmentBookingServicePointFilter,
	AppointmentBookingServicePointFilterOnChange
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointFilter/AppointmentBookingServicePointFilter";
import {ILocation} from "@/models/interfaces/ILocation";
import GetAppointmentsInternalRequest from "@/models/server/requests/GetAppointmentsInternalRequest";
import {DateTime} from "luxon";
import {
	AppointmentBookingServicePointList,
	AppointmentBookingServicePointListPagination,
	PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import ReserveTimeModal from "@/components/ReserveTimeModal/ReserveTimeModal";
import CancelAppointmentModal from "@/components/CancelAppointmentModal/CancelAppointmentModal";
import remarkGfm from "remark-gfm";
import PageDefinitions from "@/providers/PageDefinitions";

enum ServiceTypeEnum {
	'service',
	'clinic',
	'medical-field'
}

export type ServiceType = keyof typeof ServiceTypeEnum

interface IFilterData {
	medicalFields: IMedicalField[];

	doctors: IDoctor[];

	locations: INameExternalId[];
}

export interface IServicePageViewParams extends BasePageParameters {
	type: ServiceType;
	id: number;
	voucherId?: number;
}

interface IServicePageState {
	locationsData: DynamicPageData[];
	serviceData: DynamicPageData | null;
	loading: boolean | null
	defaultLocation: INameExternalId | null;

	defaultSpecialist: IDoctor | null;

	defaultMedicalField: IMedicalField | null;

	defaultDate: Date | null;
	filterData: IFilterData | null;
	availableServices: IMedicalField[];

	availableDoctors: IDoctor[];

	availableLocations: INameExternalId[];
	pagedPossibleAppointmentData: AppointmentBookingServicePointListPagination<PossibleAppointment>;
	voucherData: DynamicPageData[] | null;
}

export default class ServicePageView extends AnonymousPage<IServicePageViewParams, IServicePageState> {
	
	constructor(props: any) {
		super(props);
		this.onBackButtonEvent = this.onBackButtonEvent.bind(this)
	}

	public _selectedVoucher: React.RefObject<any> = React.createRef();
	public _loginModal: React.RefObject<Modal> = React.createRef();
	public _appointmentModal: React.RefObject<Modal> = React.createRef();

	private readonly _reserveTimeModal: React.RefObject<ReserveTimeModal> = React.createRef();
	private readonly _cancelAppointmentModal: React.RefObject<CancelAppointmentModal> = React.createRef();

	static accordionClassNames : IAccordionClassNames = {
		headerContainer: 'accordionHeaderContainer'
	}
	state: IServicePageState = {
		locationsData: [],
		serviceData: null,
		loading: null,
		defaultLocation: null,
		defaultDate: null,
		defaultSpecialist: null,
		defaultMedicalField: null,
		filterData: null,
		availableServices: [],
		availableDoctors: [],
		availableLocations: [],
		pagedPossibleAppointmentData: {
			loading: false,
			pageIndex: 0,
			pageSize: 5,
			totalCount: 25,
			items: []
		},
		voucherData: null,
	};

	private async initializeFiltersDataAsync(): Promise<void> {
		const params = this.parameters as IAppointmentBookingParams as IAppointmentBookingParams | null;

		const dynamicData: IFilterData = await this.postAsync(
			"/api/Application/GetFilterData",
			false
		);

		if (params) {
			let dateValue = params.selectedDate ? moment(params.selectedDate).toDate() : null;
			const matching: INameExternalId | null = dynamicData.locations.filter((item) => item.id === params.selectedLocation)[0];
			const doctor: IDoctor | null = dynamicData.doctors.filter((item) => item.id === params.selectedDoctor)[0];
			const care: IMedicalField | null = dynamicData.medicalFields.filter((item) => item.id === params.selectedCare)[0];


			await this.setState({
				defaultLocation: matching,
				defaultDate: dateValue,
				defaultSpecialist: doctor,
				defaultMedicalField: care
			});
		}

		await this.setState({filterData: dynamicData});

		await this.setDefaultFiltersAsync();
	}

	public async setDefaultFiltersAsync(): Promise<void> {
		if (this.state.filterData && this.state.serviceData && this.state.serviceData?.attributes.externalId) {
			await this.setState({
				defaultMedicalField: this.state.filterData?.medicalFields.filter(mf => mf.id === this.state.serviceData?.attributes.externalId)[0],
				availableServices: this.state.filterData?.medicalFields ? this.state.filterData?.medicalFields.filter(mf => mf.id === this.state.serviceData?.attributes.externalId) : [],
				availableDoctors: this.state.filterData?.doctors ?? [],
				availableLocations: this.state.filterData?.locations ?? []
			});
		}
	}

	public toSelectListItem(item: INameExternalId | IDoctor | IMedicalField): SelectListItem {
		const selectedItem = new SelectListItem();
		selectedItem.ref = item;
		selectedItem.value = item.id;
		selectedItem.text = item.name;
		return selectedItem;
	}

	private async fetchData(filterValue: AppointmentBookingServicePointFilterOnChange | null) {
		const specialist: IDoctor = filterValue?.specialist as unknown as IDoctor;
		const location: ILocation | null = filterValue?.location as any as ILocation;
		const medicalField: IMedicalField = filterValue?.medicalField as unknown as IMedicalField;

		//DST magic

		const timeRangeStart = DateTime.fromJSDate(filterValue!.timeRangeStart).setZone('Europe/Helsinki')
		let offset = timeRangeStart.isInDST ? 180 : 120;
		
		let startDateOffset;
		let endDateOffset;
		
		const startDateIsDST = DateTime.fromJSDate(filterValue!.dateRangeStart).isInDST
		const endDateIsDST = DateTime.fromJSDate(filterValue!.dateRangeEnd).isInDST
		

		startDateIsDST ? startDateOffset = 180 : startDateOffset = 120;
		endDateIsDST ? endDateOffset = 180 : endDateOffset = 120;


		const request: GetAppointmentsInternalRequest = {
			dateRangeEnd: DateTime.fromJSDate(filterValue!.dateRangeEnd).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC(endDateOffset,{keepLocalTime: true}).toJSDate(),
			timeRangeStart: DateTime.fromJSDate(filterValue!.timeRangeStart).toUTC(offset, {keepLocalTime: true}).toJSDate(),
			timeRangeEnd: DateTime.fromJSDate(filterValue!.timeRangeEnd).toUTC(offset, {keepLocalTime: true}).toJSDate(),
			dateRangeStart: DateTime.fromJSDate(filterValue!.dateRangeStart).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC(startDateOffset,{keepLocalTime: true}).toJSDate(),
			selectedService: medicalField?.id ? [medicalField.id] : filterValue?.medicalField ? [filterValue.medicalField.id] : [],
			selectedPractitioners: specialist?.id ? [specialist.id] : filterValue?.specialist ? [filterValue.specialist.id] : [],
			selectedClinics: location?.id ? [location.id] : filterValue?.location ? [filterValue.location.id] : [],
			occupationalHealth: false
		};

		const data: PossibleAppointment[] = await this.postAsync("/api/Application/GetAvailableAppointments", request);
		const pagedThing: AppointmentBookingServicePointListPagination<PossibleAppointment> = {
			loading: false,
			pageIndex: 0,
			items: data,
			pageSize: 5,
			totalCount: data.length
		};

		await this.setState({pagedPossibleAppointmentData: pagedThing});
	}

	private async reserveTimeForNormalAppointmentsAsync(): Promise<void> {
		await FenixAppController.openAppointmentsPage();
	}

	private openRemoteReception() {
		window.location.href = "https://fenix.movendosplatform.com/#login";
		return Promise.resolve(undefined);
	}
	async initializeAsync(): Promise<void> {
		await super.initializeAsync();
		await this.setState({loading: true})
		const params = this.parameters as IServicePageViewParams as IServicePageViewParams;
		if (params && params.type && params.id) {
			const service: DynamicPageResponse = await this.getAsync(`/api/Application/GetDynamicPageContent?contentType=${params.type}s&filters=${params.id}&populateAll=true`);
			if (service && service.data && service.data.length > 0) {
				await this.setState({serviceData: service.data[0]})
				if(params.type == 'service' && (params.id == 13 || params.id == 16)){
					// Loading ServiceVoucher-service, load service voucher info as well.
					const voucherData: DynamicPageResponse = await this.getAsync(`/api/Application/GetDynamicPageContent?contentType=service-vouchers&populate=*`)
					if(voucherData && voucherData.data){
						await this.setState({voucherData: voucherData.data});
					}
				}
				if(service.data[0].attributes.externalId && !service.data[0].attributes.hideFromBooking){
					// Kyseessä medicalField, eli voidaan varata aikoja. Näytetään ajanvaraus.
					const locationsData: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours&sort=externalId");
					if (locationsData && locationsData?.data) {
						await this.setState({locationsData: locationsData.data})
					}
					await this.initializeFiltersDataAsync();
				}
				
				await this.setState({loading: false})
			}
			else{
				await PageRouteProvider.redirectAsync(PageDefinitions.serviceRoute);
			}
		}

		if(params.voucherId){
			this._selectedVoucher.current?.scrollIntoView({block: "start", inline: "start"});
		}
		else{
			window.scrollTo(0, 0);
		}
	}
	
	componentDidMount(): Promise<void> {
		window.addEventListener('popstate', this.onBackButtonEvent);
		return super.componentDidMount();
	}
	
	componentWillUnmount(): Promise<void> {
		window.removeEventListener('popstate', this.onBackButtonEvent);
		return super.componentWillUnmount();
	}

	public async onBackButtonEvent() {
		let type;
		let id;
		let voucherId;
		
		const typeMatch = window.location.search.match(/type=\s*([a-zA-z]+)(&|\z)/i)
		if(typeMatch && typeMatch[1]){
			type = typeMatch[1]
		}
		const idMatch = window.location.search.match(/id=\s*([\d]+)/i);
		if(idMatch && idMatch[1]){
			id = idMatch[1];
		}

		if(window.location.search.indexOf('voucherId') >= 0){
			const voucherIdMatch = window.location.search.match(/voucherId=\s*([\d]+)/i);
			if(voucherIdMatch && voucherIdMatch[1]){
				voucherId = voucherIdMatch[1]
			}
		}

		await this.setState({
			serviceData: null,
			loading: true,
			defaultLocation: null,
			defaultDate: null,
			defaultSpecialist: null,
			defaultMedicalField: null,
			filterData: null,
			availableServices: [],
			availableDoctors: [],
			availableLocations: [],
			pagedPossibleAppointmentData: {
				loading: false,
				pageIndex: 0,
				pageSize: 5,
				totalCount: 25,
				items: []
			},
			voucherData: null,
		})
		if (type && id) {
			const service: DynamicPageResponse = await this.getAsync(`/api/Application/GetDynamicPageContent?contentType=${type}s&filters=${id}&populateAll=true`);
			if (service && service.data) {
				await this.setState({serviceData: service.data[0]})
				if(type == 'service' && (Number(id) == 13 || Number(id) == 16)){
					// Loading ServiceVoucher-service, load service voucher info as well.
					const voucherData: DynamicPageResponse = await this.getAsync(`/api/Application/GetDynamicPageContent?contentType=service-vouchers&populate=*`)
					if(voucherData && voucherData.data){
						await this.setState({voucherData: voucherData.data});
					}
				}
				if(service.data[0].attributes.externalId && !service.data[0].attributes.hideFromBooking){
					await this.initializeFiltersDataAsync();
				}

				await this.setState({loading: false})
			}
			else{
				await PageRouteProvider.redirectAsync(PageDefinitions.serviceRoute);
			}
		}
		if(voucherId && this._selectedVoucher.current){
			setTimeout(this._selectedVoucher.current?.scrollIntoView({block: "start", inline: "start", behavior: "smooth"}), 800);
		}
		else{
			window.scrollTo(0, 0);
		}
		
	}

	public render(): React.ReactNode {
		return (
			<PageContainer fullWidth className={styles.pageContainer}>
				{this.state.loading ?
					<LoadingSpinner />
					:
					<>
						<PageHeader title={this.state.serviceData?.attributes?.name ?? ''} className={styles.pageHeader}/>
						<div className={styles.pageContent}>
							<div className={styles.fluidContent}>
								{this.state.serviceData && this.state.serviceData?.attributes.content &&
										<ReactMarkdown>{this.state.serviceData?.attributes.content}</ReactMarkdown>
								}
								{this.state.voucherData &&
									<div className={styles.voucherAccordionContainer}>
										{this.state.voucherData.map(voucher => {
											return(
												<div ref={this.parameters?.voucherId && this.parameters.voucherId == voucher.id ? this._selectedVoucher : undefined}>
													<Accordion header={voucher.attributes.name}
																		 autoCollapse={false}
																		 className={styles.clinicAccordion}
																		 classNames={ServicePageView.accordionClassNames}
																		 expanded={!!(this.parameters?.voucherId && this.parameters.voucherId == voucher.id)}
													>
														<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{voucher.attributes.content}</ReactMarkdown>
													</Accordion>
												</div>
											);
										})}
									</div>
								}
								{(this.state.serviceData?.attributes.service_vouchers?.data && this.state.serviceData?.attributes.service_vouchers?.data.length > 0) &&
									<div className={styles.serviceVouchersContainer}>
                      <div className={styles.availableVouchersHeader}>
                          <Icon name="fa-ticket-alt" style={2} size={IconSize.Large} customStyle={{color: "var(--primary)"}}/>
                          <span>Saatavilla olevat palvelusetelit</span>
                      </div>
										<div className={styles.availableVouchersButtonContainer}>
											{this.state.serviceData?.attributes.service_vouchers?.data.map(voucher => {
												return(
													<Button
														label={voucher.attributes.name}
														type={ButtonType.Light}
														icon={"fa-chevron-circle-right"}
														iconPosition={1}
														onClick={async () => {
															const params: IServicePageViewParams = {
																type: "service",
																id: 13,
																voucherId: voucher.id
															};
															await PageRouteProvider.redirectAsync(PageDefinitions.servicePageView.route({params}));
														}}
													/>
												);
											})}
										</div>
									</div>
								}
								{this.state.serviceData && this.state.serviceData?.attributes.externalId &&
										<div>
                        <div className={styles.reserveTimeCard}>
                            <div className={styles.cardHeader}>
                                <Icon name="clock" style={2} size={IconSize.Large}/>
                                <span>{Localizer.buttonBookAppointment}</span>
                            </div>
                            <div className={styles.reserveTimeNav}>
                                <Button
                                    label={Localizer.homePageHeaderLocation}
                                    type={ButtonType.Primary}
                                    onClick={async () => await this.reserveTimeForNormalAppointmentsAsync()}
                                    icon={"hospital"}
                                    className={styles.appointmentButton}
                                />
                                <Button
                                    label={Localizer.homePageHeaderRemoteBooking}
                                    type={ButtonType.Default}
                                    icon={"laptop-medical"}
                                    onClick={() => this.openRemoteReception()}
                                    className={styles.appointmentButton}
                                />
                            </div>
														
														<AppointmentBookingServicePointFilter
															defaultDate={this.state.defaultDate}
															defaultLocation={this.state.defaultLocation}
															defaultSpecialist={this.state.defaultSpecialist}
															defaultMedicalField={this.state.defaultMedicalField}
															medicalFieldList={this.state.availableServices}
															specialistList={this.state.availableDoctors}
															locationList={this.state.availableLocations}
															medicalFieldToListItem={(item) => this.toSelectListItem(item)}
															locationToListItem={(item) => this.toSelectListItem(item)}
															specialistToListItem={(item) => this.toSelectListItem(item)}
															listItemToLocation={(item) => item.ref}
															listItemToSpecialist={(item) => item.ref}
															listItemToTypeOfCare={(item) => item.ref}
															onChange={async (filterValue) => await this.fetchData(filterValue)}
														/>

                            <div className={styles.search}>
                                <Button label={Localizer.genericSearch} type={ButtonType.Primary}/>
                            </div>
                            <AppointmentBookingServicePointList
                                pagedData={this.state.pagedPossibleAppointmentData}
                                onShowMoreClick={async () => {
																	//  Test pagination setup
																	this.setState({
																		pagedPossibleAppointmentData: {
																			...this.state.pagedPossibleAppointmentData,
																			loading: true
																		}
																	});
																	//  Simulate data fetching.
																	setTimeout(() => {
																		this.setState({
																			pagedPossibleAppointmentData: {
																				...this.state.pagedPossibleAppointmentData,
																				pageIndex: this.state.pagedPossibleAppointmentData.pageIndex + 1,
																				items: [...this.state.pagedPossibleAppointmentData.items],
																				loading: false
																			}
																		});
																	}, 1000);
																}}
                                onReserveTimeClick={async (possibleAppointment: PossibleAppointment) => {
																	this._reserveTimeModal.current?.openAsync(
																		possibleAppointment,
																		false,
																		this.state.locationsData
																	);
																}}
                            ></AppointmentBookingServicePointList>
                        </div>
										</div>
								}
								{(this.state.serviceData?.attributes.clinic_sub_items && this.state.serviceData?.attributes.clinic_sub_items.data.length > 0) &&
										<div className={styles.accordionContainer}>
											<div className={styles.readMoreDiv}>
                          <Icon name="fa-info-circle" style={2} size={IconSize.Normal}/>
													<span>Lue lisää</span>
											</div>
											{
												this.state.serviceData?.attributes.clinic_sub_items.data.map(accordion => {
													return(
														<Accordion header={accordion.attributes.name}
																			 autoCollapse={false}
																			 className={styles.clinicAccordion}
																			 classNames={ServicePageView.accordionClassNames}
														>
															<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{accordion.attributes.content}</ReactMarkdown>
														</Accordion>
													);
													
												})
											}
										</div>
								}
								{(this.state.serviceData?.attributes.accordions && this.state.serviceData?.attributes.accordions.length > 0) &&
										<div className={styles.accordionContainer}>
                        <div className={styles.readMoreDiv}>
                            <Icon name="fa-info-circle" style={2} size={IconSize.Normal}/>
														<span>Lue lisää</span>
                        </div>
											{this.state.serviceData?.attributes.accordions.map(accordion => {
												return(
													<Accordion
														header={accordion.title}
														autoCollapse={false}
														className={styles.clinicAccordion}
														classNames={ServicePageView.accordionClassNames}
													>
														<ReactMarkdown>{accordion.content}</ReactMarkdown>
													</Accordion>
												);
											})}
										</div>
								}
								{(this.state.serviceData?.attributes.doctors && this.state.serviceData?.attributes.doctors.data.length > 0) &&
										<>
											<div className={styles.teamIncludesHeader}>
													<span>{this.state.serviceData?.attributes.name}{Localizer.servicesTeamIncludes}</span>
											</div>
											<div className={styles.doctorsContainer}>
												{this.state.serviceData?.attributes.doctors.data.map((doc) => {
													return (
														<div className={styles.doctor} key={`doctor_${doc.id}`}
																 onClick={async () => {PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: doc.attributes.externalId}}))}}>
															<img src={doc?.attributes?.image?.data?.attributes?.url ?? FenixAppController.defaultDoctorImage()} className={styles.doctorImage}/>
															<div className={styles.titleContainer}>
																<h3>{doc.attributes.firstName + " " + doc.attributes.lastName}</h3>
																<center>{doc.attributes.title}</center>
															</div>
														</div>
													)
												})}
											</div>
										</>
								}
								{(this.state.serviceData?.attributes.secondaryService) &&
									<>
										{this.state.serviceData?.attributes.secondaryService.title &&
												<div className={styles.secondaryServiceTitle}>
														<h3>{this.state.serviceData?.attributes.secondaryService.title}</h3>
												</div>
										}
										{this.state.serviceData?.attributes.secondaryService.content &&
												<div className={styles.secondaryServiceContent}>
														<ReactMarkdown>{this.state.serviceData?.attributes.secondaryService.content}</ReactMarkdown>
												</div>
										}
										{(this.state.serviceData?.attributes.secondaryService.accordions && this.state.serviceData?.attributes.secondaryService.accordions.length > 0) &&
                        <div className={styles.secondaryServiceAccordions}>
													{this.state.serviceData?.attributes.secondaryService.accordions.map(accordion => {
														return(
															<Accordion
																header={accordion.title}
																autoCollapse={false}
																className={styles.clinicAccordion}
																classNames={ServicePageView.accordionClassNames}
															>
																<ReactMarkdown>{accordion.content}</ReactMarkdown>
															</Accordion>
														);
													})}
                        </div>
										}
									</>
								}
							</div>
						</div>
					</>
				}
				<ReserveTimeModal
					ref={this._reserveTimeModal}
					onTimeReserved={(sender, time: PossibleAppointment) => FenixAppController.onReserveTime(time)}
				/>
			</PageContainer>

		);
	}

}



