import React from "react";
import styles from "./SearchPageHit.module.scss";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {DynamicPageAttributes} from "@/models/cms/DynamicPageResponse";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import ReactMarkdown from "react-markdown";
import {Icon, IconSize, IconStyle} from "@reapptor-apps/reapptor-react-components";
import {IServicePageViewParams} from "@/pages/ServicePageView/ServicePageView";

export const SearchPageHit = ({ hit, query }: {hit: DynamicPageAttributes, query: string}) => {

	const primaryColorStyle: React.CSSProperties = {
		color: "#F28325"
	}
	const getCategory = (hit: DynamicPageAttributes) => {
		switch(hit._federation?.indexUid){
			case 'doctor':
				return(Localizer.searchPagedoctor)
			case 'medical-field':
				return(Localizer.searchPagemedicalField)				
			case 'service':
				return(Localizer.searchPageservice)
			case 'clinic':
				return(Localizer.searchPageclinic)				
			case 'service-voucher':
				return(Localizer.searchPagevoucher)
			case 'location':
				return(Localizer.homePageHeaderLocation)
			case 'blog-post':
				return(Localizer.blogPageTitle)
			case 'occupational-health':
				return(Localizer.occupationalHealthPageTitle)
			case 'company':
				return(Localizer.topNavCompany)
			case 'job-posting':
				return (Localizer.jobsPageTitle)
		}
		return hit._federation?.indexUid;
	}
	
	const processContent = (content: string) => {
		if(content && typeof content !== 'undefined'){
			var processedContent = content;
			if(content.startsWith('###')){
				const endIndex = content.indexOf('\n');
				processedContent = content.substring(endIndex);
			}
			if(content.startsWith('##')){
				const endIndex = content.indexOf('\n');
				processedContent = content.substring(endIndex);
			}
			return processedContent;
		}
		
	}

	const getHeader = (hit: DynamicPageAttributes) => {
		if(hit._federation?.indexUid == 'doctor'){
			return `${hit.firstName} ${hit.lastName}`
		}
		if(hit.name){
			return hit.name
		}
		if(hit._federation?.indexUid == 'blog-post' || hit._federation?.indexUid == 'occupational-health'){
			return hit.title
		}
		if(hit._federation?.indexUid == 'job-posting'){
			return Localizer.jobsPageTitle
		}
	}

	const getContent = (hit: DynamicPageAttributes) => {
		const checkFieldsForMatch = (query: string) => {
			
		}
		
		if(hit._formatted){
			/*for(let [key, value] of Object.entries(hit._formatted)) {
				if(typeof value == 'string'){
					if(value.toLowerCase().includes(`**${query}**`.toLowerCase()) || value.toLowerCase().includes(`${query}`.toLowerCase())){
						return processContent(value);
					}
				}
				if(typeof value == 'object' && value !== null){
					for (let [key2, value2] of Object.entries(value)){
						if(typeof value2 == 'string'){
							if((value2.toLowerCase().includes(`**${query}**`.toLowerCase()) || value2.toLowerCase().includes(`${query}`.toLowerCase()))){
								return processContent(value2);
							}
						}
					}
				}
			}*/
			if(hit._formatted.content?.toLowerCase().includes(`**${query}**`.toLowerCase())){
				return processContent(hit._formatted.content)
			}
			if(hit._formatted.description?.toLowerCase().includes(`**${query}**`.toLowerCase())){
				return processContent(hit._formatted.description)
			}
			if(hit._formatted.description){
				return hit._formatted.description.replaceAll(query, `**${query}**`);
			}
			if(hit._formatted.text?.toLowerCase().includes(`**${query}**`.toLowerCase())){
				return processContent(hit._formatted.text)
			}
			if(hit._formatted.text){
				return hit._formatted.text.replaceAll(query, `**${query}**`);
			}
			if(hit._formatted.secondaryService){
				if(hit._formatted.secondaryService?.content?.toLowerCase().includes(`**${query}**`.toLowerCase()) || hit._formatted.secondaryService?.content?.toLowerCase().includes(`**`.toLowerCase())){
					return processContent(hit._formatted.secondaryService?.content)
				}
				if(hit._formatted.secondaryService?.title?.toLowerCase().includes(`**${query}**`.toLowerCase()) || hit._formatted.secondaryService?.title?.toLowerCase().includes(`**`.toLowerCase())){
					return processContent(hit._formatted.secondaryService?.title)
				}
				if(hit._formatted.secondaryService.accordions){
					for (let [key, value] of Object.entries(hit._formatted.secondaryService.accordions)){
						for (let [key2, value2] of Object.entries(value)){
							if(typeof value2 == 'string'){
								if((value2.toLowerCase().includes(`**${query}**`.toLowerCase()) || value2.toLowerCase().includes(`**`.toLowerCase()))){
									return processContent(value2);
								}
							}
						}
						
					}
				}
			}
		}
			return ' ';
	}
	
	const getTitle = (hit: DynamicPageAttributes) => {
		if(hit._federation?.indexUid !== "blog-post" && hit._federation?.indexUid !== "occupational-health"){
			return hit._formatted?.title.replaceAll(query, `**${query}**`) ?? hit.title;
		}
		else{
			return '';
		}
	}
	
	const redirectToHit = async (hit: DynamicPageAttributes) => {
		switch(hit._federation?.indexUid){
			case 'doctor':
				await PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: hit.externalId}}));
				break;
			case 'medical-field':
				await PageRouteProvider.redirectAsync(PageDefinitions.servicePageView.route({params: {type: "medical-field", id: +hit.id}}));
				break;
			case 'service':
				await PageRouteProvider.redirectAsync(PageDefinitions.servicePageView.route({params: {type: "service", id: +hit.id}}));
				break;
			case 'clinic':
				await PageRouteProvider.redirectAsync(PageDefinitions.servicePageView.route({params: {type: "clinic", id: +hit.id}}));
				break;
			case 'service-voucher':
				const params: IServicePageViewParams = {
					type: "service",
					id: 13,
					voucherId: +hit.id
				};
				await PageRouteProvider.redirectAsync(PageDefinitions.servicePageView.route({params}));
				break;
			case 'location':
				await PageRouteProvider.redirectAsync(
					PageDefinitions.location.route({
						params: {selectedLocation: hit.externalId}
					})
				)
				break;
			case 'blog-post':
				await PageRouteProvider.redirectAsync(PageDefinitions.blogpost.route({params: {post: hit.slug!}}));
				break;
			case 'occupational-health':
				await PageRouteProvider.redirectAsync(PageDefinitions.occupationalHealth.route());
				break;
			case 'job-posting':
				await PageRouteProvider.redirectAsync(PageDefinitions.jobPosting.route({params: {title: hit.slug!}}));
				break;
			case 'company':
				await PageRouteProvider.redirectAsync(PageDefinitions.company.route());
				break;
		}
	}
	
	return (
		<div className={styles.hitWrapper}>
			<div className={styles.hitTextContainer} onClick={() => redirectToHit(hit)}>
				<div className={styles.hitCategory}>
					{getCategory(hit)}
				</div>
				<div className={styles.hitHeader}>
					{getHeader(hit)}
				</div>
				{(hit.title && hit._federation?.indexUid !== 'blog-post' && hit._federation?.indexUid !== 'occupational-health') &&
            <div className={styles.hitTitle}>
								<ReactMarkdown>{getTitle(hit)}</ReactMarkdown>
            </div>
				}
				<div className={styles.hitContent}>
					<ReactMarkdown>{getContent(hit) ?? ''}</ReactMarkdown>
				</div>
			</div>
			<div className={styles.hitButtonContainer}>
				<button className={styles.hitButton} onClick={() => redirectToHit(hit)}> 
					<Icon 			name={"chevron-right"}
										 size={IconSize.X2}
										 customStyle={primaryColorStyle}
										 style={IconStyle.Light}
					/>
				</button>
					
			</div>
		</div>
	);
};