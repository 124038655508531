import React from "react";
import {BaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    Form,
    IStringInputModel,
    Modal,
    ModalSize,
    PhoneInput,
    Spinner,
    TextAreaInput,
    TextInput,
} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import {Dictionary} from "typescript-collections";
import {
    PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import FenixAppController from "@/pages/FenixAppController";

import styles from './ReserveTimeModal.module.scss'
import {LoginForm} from "@/components/LoginForm/LoginForm";
import ErpLoginResponse from "@/models/server/responses/ErpLoginResponse";
import ReserveAppointmentResponse from "@/models/server/responses/ReserveAppointmentResponse";
import UpdatePatientDataResponse from "@/models/server/responses/UpdatePatientDataResponse";
import {isEmpty} from "@/helpers/StringIsEmpty";
import {DynamicPageData} from "@/models/cms/DynamicPageResponse";
import {EditContactInfoForm} from "@/components/EditContactInfoForm/EditContactInfoForm";


interface IReserveTimeModalProps {
    title?: string;

    onTimeReserved(sender: ReserveTimeModal, appointment: PossibleAppointment): Promise<void>;
}

interface IReserveTimeModalState {
    possibleAppointment: PossibleAppointment | null;

    occupationalHealth: boolean;

    register: boolean

    reservedAppointmentId: string | null;
    reservedAppointment: PossibleAppointment | null;

    isloading: boolean;

    validationError: string | null;
    userEmail: string | null;
    locationsData: DynamicPageData[];
    editViewOpen: boolean;
    reminderViewOpen: boolean;
}

export default class ReserveTimeModal extends BaseComponent<IReserveTimeModalProps, IReserveTimeModalState> {

    state: IReserveTimeModalState = {
        occupationalHealth: false,
        possibleAppointment: null,
        register: false,
        reservedAppointmentId: null,
        reservedAppointment: null,
        isloading: false,
        validationError: null,
        userEmail: null,
        locationsData: [],
        editViewOpen: false,
        reminderViewOpen: false,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public ssn: IStringInputModel = {value: ""};

    public address: IStringInputModel = {value: ""};
    public postalCode: IStringInputModel = {value: ""};
    public city: IStringInputModel = {value: ""};
    public phone: IStringInputModel = {value: ""};
    public email: IStringInputModel = {value: ""};


    public info: IStringInputModel = {value: ""};

    public _loginModal: React.RefObject<Modal> = React.createRef();
    public _appointmentModal: React.RefObject<Modal> = React.createRef();

    public registerForm: React.RefObject<Form> = React.createRef();
    public formRef: React.RefObject<any> = React.createRef();
    public reserveTimeFormRef: React.RefObject<any> = React.createRef();
    public loginForm: React.RefObject<Form> = React.createRef();
    public emailForm: React.RefObject<Form> = React.createRef();
    public editContactInfoForm: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }


    public async openAsync(appointment: PossibleAppointment, occupationalHealth: boolean, locationsData: DynamicPageData[]): Promise<void> {
        await this.setState({possibleAppointment: appointment, occupationalHealth: occupationalHealth, locationsData})
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync();
        }
    }

    public getLocationInfo(locationCode: string | undefined){
        if(locationCode){
            const location = this.state.locationsData.find(location => location.attributes.externalId == locationCode)
            if(location){
                return(
                  <div>
                      <p>
                          <span>{Localizer.reserveTimeModalAppointmentDetailsMedicalCenterFenix + ' ' + location.attributes.name}</span>
                      </p>
                      <p>
                          <span>{location.attributes.address}</span>
                      </p>
                      <p>
                          <span>{location.attributes.postalCode + ' ' + location.attributes.town}</span>
                      </p>
                  </div>
                )
            }
            else{
                return <></>
            }
        }
        else{
            return <></>
        }
    }
    
    public async onOpenModalAsync(): Promise<void> {
    }

    public async closeAsync(): Promise<void> {
        await this.setState({possibleAppointment: null, register: false, reservedAppointmentId: null, editViewOpen: false})
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }
    
    public async openEditView(): Promise<void> {
        await this.setState({editViewOpen: true, reminderViewOpen: false})
        await this.reRenderAsync();
    }
    
    public async closeEditView(): Promise<void> {
        await this.setState({editViewOpen: false})
        await this.reRenderAsync();
    }
    
    public async openReminderView(): Promise<void> {
        await this.setState({reminderViewOpen: true})
    }

    public async handleLoginSubmit(data: Dictionary<string, any>): Promise<void> {
        await this.setState({validationError: null})
        const loginRequest = {
            "occupationalHealth": this.state.occupationalHealth
        } as any;
        data.keys().map((key) => {
            return (loginRequest[key] = data.getValue(key));
        });


        await this.setState({isloading: true})

        const response: ErpLoginResponse = await this.postAsync("/api/Application/ErpLogin", loginRequest);

        await this.setState({isloading: false})

        if (this.state.occupationalHealth) {
            if (!response.occupationalContractFound) {
                await this.setState({validationError: Localizer.reserveTimeModalNoOccupationalHealthFound})
            }
        }

        if (!response.failed) {
            await this.reRenderAsync();

        } else {
            this.loginForm.current?.setValidationErrorsAsync(Localizer.cancelAppointmentModalLoginFailed);
        }
    }
    
    public async handleEmailSubmit(data: Dictionary<string, any>): Promise<void> {
        const request = {
            user: FenixAppController.userContext.user
        } as any;
        data.keys().map((key) => {
            return (request[key] = data.getValue(key));
        });

        const response: UpdatePatientDataResponse = await this.postAsync("/api/Application/SaveUserEmail", request);
        if(!response.failed) {
            await this.setState({validationError: null})
            const loginRequest = {
                "occupationalHealth": this.state.occupationalHealth,
                "SSN": FenixAppController.userContext.user?.username,
                "FirstName": FenixAppController.userContext.user?.firstname,
                "LastName": FenixAppController.userContext.user?.lastName,
                "Email": request.Email
            } as any;

            await this.setState({isloading: true})

            const response: ErpLoginResponse = await this.postAsync("/api/Application/ErpLogin", loginRequest);

            await this.setState({isloading: false})

            if (this.state.occupationalHealth && !response.occupationalContractFound) {
                await this.setState({validationError: Localizer.reserveTimeModalNoOccupationalHealthFound})
            }

            if (!response.failed) {
                if(!response.emailIsMissing){
                    await this.setState({userEmail: request.Email})
                }
                await this.reRenderAsync();

            } else {
                this.emailForm.current?.setValidationErrorsAsync(Localizer.cancelAppointmentModalLoginFailed);
            }
            
        }
        else {
            this.emailForm.current?.setValidationErrorsAsync(Localizer.emailFormUpdateFailed);
        }
    }

    public async handleUpdateContactInfo(data: Dictionary<string, any>): Promise<void> {
        const request = {
            user: FenixAppController.userContext.user
        } as any;
        data.keys().map((key) => {
            return (request[key] = data.getValue(key));
        });

        const response: UpdatePatientDataResponse = await this.postAsync("/api/Application/SaveUserContactInfo", request);
        if(!response.failed) {
            await this.setState({validationError: null})
            const loginRequest = {
                "occupationalHealth": this.state.occupationalHealth,
                "SSN": FenixAppController.userContext.user?.username,
                "FirstName": FenixAppController.userContext.user?.firstname,
                "LastName": FenixAppController.userContext.user?.lastName,
                "Email": request.Email
            } as any;

            await this.setState({isloading: true})

            const response: ErpLoginResponse = await this.postAsync("/api/Application/ErpLogin", loginRequest);

            await this.setState({isloading: false})

            if (this.state.occupationalHealth && !response.occupationalContractFound) {
                await this.setState({validationError: Localizer.reserveTimeModalNoOccupationalHealthFound})
            }

            if (!response.failed) {
                if(!response.emailIsMissing){
                    await this.setState({userEmail: request.Email, editViewOpen: false})
                }
                await this.reRenderAsync();

            } else {
                this.editContactInfoForm.current?.setValidationErrorsAsync(Localizer.cancelAppointmentModalLoginFailed);
            }

        }
        else {
            this.editContactInfoForm.current?.setValidationErrorsAsync(Localizer.reserveTimeModalSavingContactInfoFailed);
        }
    }
    


    public async handleRegisterSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const request = {} as any;
        data.keys().map((key) => {
            return (request[key] = data.getValue(key));
        });

        const error: string | null = await this.postAsync("/api/Application/RegisterAndLogin", request);

        if (!error) {
            await this.reRenderAsync();
        } else {
            this.registerForm.current?.setValidationErrorsAsync(error);
        }
    }

    public async handleReserveTime(data: Dictionary<string, any>): Promise<void> {
        if (!this.state.isloading) {
            await this.setState({isloading: true});
            let appointment: PossibleAppointment = this.state.possibleAppointment!;
            appointment.comment = this.info.value;

            const reserveAppointmentResponse: ReserveAppointmentResponse = await this.postAsync("/api/Application/ReserveAppointment", appointment);

            if (reserveAppointmentResponse.failed) {
                this.reserveTimeFormRef.current?.setValidationErrorsAsync(reserveAppointmentResponse.errorMessage);

            } else {
                await this.setState({
                    reservedAppointmentId: reserveAppointmentResponse.appointmentId!,
                    reservedAppointment: reserveAppointmentResponse.appointment!,
                    isloading: false
                });
            }
        }
    }

    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public get showLoginForm(): boolean {
        return !FenixAppController.userContext.user && !this.state.register;
    }

    public get showConfirmSelectedAppointment(): boolean {
        return FenixAppController.userContext.user != null && !this.state.reservedAppointmentId && !FenixAppController.userContext.user.missingEmail && !this.state.editViewOpen && !this.state.reminderViewOpen;
    }

    public get showRegisterForm(): boolean {
        return !FenixAppController.userContext.user && this.state.register;
    }

    public get showConfirmation(): boolean {
        return (FenixAppController.userContext.user != null && this.state.reservedAppointmentId != null);
    }
    
    public get showContactInfoEditView(): boolean {
        return (this.state.editViewOpen)
    }
    
    public get showContactInfoReminderView(): boolean {
        return (this.state.reminderViewOpen)
    }

    private get getTitle() {
        if (this.showConfirmation) {
            return Localizer.reserveTimeModalTimeBooked
        }
        if (this.showRegisterForm) {
            return Localizer.reserveTimeModalRegisterUsername
        }
        if (this.showLoginForm) {
            return Localizer.reserveTimeModalLogin
        }
        if (this.showConfirmSelectedAppointment) {
            return Localizer.reserveTimeModalBookingConfirmation
        }
        if(FenixAppController.userContext.user?.missingEmail){
            return Localizer.reserveTimeModalEmailMissing
        }
        if (this.showContactInfoEditView) {
            return Localizer.reserveTimeModalEditContactInfo
        }
        if (this.showContactInfoReminderView) {
            return Localizer.contactInfoReminderViewTitle
        }

        return "..."
    };


    private validSsn = (ssn: string | null): string | null => {
        var phoneRegex = /^[0-9]{6}[+Aa-][0-9]{3}[A-z0-9]$/;
        return (!ssn || !ssn.match(phoneRegex)) ? "Tarkista henkilötunnus" : null;
    };


    public render(): React.ReactNode {
        return (
            <Modal className={styles.confirmModal}
                   bodyClassName={this.css("")}
                   id={"qrModal"}
                   ref={this._modalRef}
                   title={this.getTitle}
                   onClose={() => this.closeAsync()}
                   onOpen={() => this.onOpenModalAsync()}
            >

                {
                    (this.showLoginForm) && (
                        <div className={""}>
                            {
                                this.state.validationError && (
                                    <p className={styles.validationError}>{this.state.validationError}</p>
                                )
                            }
                            <LoginForm
                                formSubmit={async (data: Dictionary<string, any>) => await this.handleLoginSubmit(data)}
                                loginRef={this.loginForm}
                            />
                            <hr/>
                            <p>
                                {Localizer.reserveTimeModalfirstTimeBookingText}
                            </p>
                            <Button label={Localizer.reserveTimeModalCreateUser}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.setState({register: true})}
                            />

                        </div>
                    )
                }
                {/*{
                    FenixAppController.userContext.user?.missingEmail && (
                      <div>
                          <p>
                              {Localizer.reserveTimeModalMissingEmailMessage}
                          </p>
                          <EmailForm formSubmit={async (data: Dictionary<string, any>) => await this.handleEmailSubmit(data)}
                                     emailFormRef={this.emailForm} />
                      </div>
                  )
                }*/}
                {
                    (this.state.reminderViewOpen &&
                        <div>
                            <p>
                                {Localizer.contactInfoReminderViewMessage}
                            </p>
                            <p>
                                {Localizer.contactInfoReminderViewMessagePt2}
                            </p>
                            <div className={styles.bottomButtonDiv}>
                                <Button onClick={() => this.openEditView()} label={Localizer.reserveTimeModalEditContactInfo} type={ButtonType.Primary}/>
                                <Button label={Localizer.genericCancel} type={ButtonType.Light} onClick={async () => this.setState({reminderViewOpen: false})}/>
                            </div>
                        </div>
                    )
                }
                {
                    (this.showRegisterForm) && (
                        <div className={""}>
                            <Form id="form"
                                  ref={this.registerForm}

                                  onSubmit={async (_, data) => await this.handleRegisterSubmitAsync(data)}>

                                <TextInput required
                                           maxLength={35}
                                           id={"firstName"}
                                           label={Localizer.formInputFirstname}
                                           model={this.firstname}
                                />

                                <TextInput required
                                           maxLength={35}
                                           id={"lastName"}
                                           label={Localizer.formInputLastname}
                                           model={this.lastname}
                                />

                                <TextInput required
                                           maxLength={11}
                                           id={"ssn"}
                                           validators={[() => this.validSsn(this.ssn.value)]}
                                           label={Localizer.formInputSSN}
                                           model={this.ssn}
                                />

                                <TextInput required
                                           maxLength={30}
                                           id={"address"}
                                           label={Localizer.genericAddress}
                                           model={this.address}
                                />
                                <TextInput required
                                           maxLength={5}
                                           id={"postalCode"}
                                           label={Localizer.genericPostalCode}
                                           model={this.postalCode}
                                />
                                <TextInput required
                                           maxLength={30}
                                           id={"city"}
                                           label={Localizer.genericCity}
                                           model={this.city}
                                />

                                <PhoneInput required
                                            maxLength={12}
                                            id={"phone"}
                                            label={Localizer.genericPhoneNumber}
                                            model={this.phone}
                                />
                                <TextInput required
                                           maxLength={100}
                                           id={"email"}
                                           label={Localizer.genericEmail}
                                           model={this.email}
                                />


                                <Button submit label={Localizer.reserveTimeModalLogin} type={ButtonType.Orange}/>
                            </Form>

                        </div>
                    )
                }
                {
                    (this.showConfirmSelectedAppointment) && (
                        <div>
                            <Form id="form"
                                  ref={this.reserveTimeFormRef}
                                  onSubmit={async (_, data) => await this.handleReserveTime(data)}>

                                <div className={styles.confirmContainer}>
                                    <div>
                                        <p className={styles.headerSpan}><span>{Localizer.reserveTimeModalYourInformation}</span></p>
                                        <div className={styles.appointmentInfoDiv}>
                                            <p>{Localizer.formInputFirstname}:</p> <span>{FenixAppController.userContext.user?.firstname}</span>
                                            <p>{Localizer.formInputLastname}:</p> <span>{FenixAppController.userContext.user?.lastName}</span>
                                            <p>{Localizer.formInputSSN}:</p> <span>{FenixAppController.userContext.user?.username}</span>
                                            <p>{Localizer.formInputEmail}:</p> <span className={!isEmpty(FenixAppController.userContext.user?.email) ? '' : styles.missingInfoText}>
                                            {!isEmpty(FenixAppController.userContext.user?.email) ? FenixAppController.userContext.user?.email : Localizer.reserveTimeModalMissingInfo}
                                            </span>
                                            <p>{Localizer.formInputPhone}:</p> <span className={!isEmpty(FenixAppController.userContext.user?.phone) ? '' : styles.missingInfoText}>
                                            {!isEmpty(FenixAppController.userContext.user?.phone) ? FenixAppController.userContext.user?.phone : Localizer.reserveTimeModalMissingInfo}
                                        </span>
                                        </div>
                                        <div className={styles.checkInfoTextDiv}>
                                            <p><span>{Localizer.reserveTimeModalCheckInfoText}</span></p>
                                        </div>
                                        <div className={styles.editContactInfoButtonDiv}>
                                            <Button type={ButtonType.Primary}
                                                    label={Localizer.reserveTimeModalEditContactInfo}
                                                    onClick={() => this.openEditView()}
                                            />
                                        </div>
                                        
                                        

                                    </div>
                                    <div>
                                        <p className={styles.headerSpan}><span>{Localizer.reserveTimeModalAppointmentDetails}</span></p>
                                        <div className={styles.appointmentInfoDiv}>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsDate}</p> <span>{this.state.possibleAppointment?.date}.</span>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsTime}</p> <span>{this.state.possibleAppointment?.time}</span>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsDuration}</p> <span>{this.state.possibleAppointment?.durationInMin} min</span>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsDoctor}</p> <span>{this.state.possibleAppointment?.specialistName}</span>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsSpecialty}</p> <span>{this.state.possibleAppointment?.specialistSpeciality}</span>
                                            <p>{Localizer.reserveTimeModalAppointmentDetailsLocation}</p> {this.getLocationInfo(this.state.possibleAppointment?.locationCode)}
                                        </div>
                                    </div>
                                    
                                </div>

                                <TextAreaInput
                                    id={"reason"}
                                    label={Localizer.reserveTimeModalMoreInformation}
                                    model={this.info}
                                    maxLength={500}
                                />
                                <div className={styles.bottomButtonDiv}>
                                    {(isEmpty(FenixAppController.userContext.user?.email) || isEmpty(FenixAppController.userContext.user?.phone)) &&
                                    <Button
                                      onClick={() => this.openReminderView()}
                                      label={Localizer.reserveTimeModalConfirmBooking}
                                      type={ButtonType.Primary}
                                    />}
                                    {(!isEmpty(FenixAppController.userContext.user?.email) && !isEmpty(FenixAppController.userContext.user?.phone)) &&
                                      <Button submit
                                              label={Localizer.reserveTimeModalConfirmBooking}
                                              type={ButtonType.Primary}
                                      />
                                    }
                                    
                                    <Button type={ButtonType.Light} label={Localizer.genericCancel} onClick={() => this.closeAsync()} />
                                </div>
                            </Form>
                        </div>
                    )
                }
                {
                    (this.showContactInfoEditView) && (
                      <div>
                          <EditContactInfoForm formSubmit={async (data: Dictionary<string, any>) => await this.handleUpdateContactInfo(data)}
                                               editContactInfoFormRef={this.editContactInfoForm}
                                               cancelFunction={() => this.closeEditView()}
                          />
                      </div>
                  )
                }
                {
                    (FenixAppController.userContext.user && this.state.reservedAppointmentId && this.state.reservedAppointment) && (
                        <div>
                            {(this.state.reservedAppointment.speciality1 == "ÖPUH" || 
                              this.state.reservedAppointment.speciality1 == "LASE" || 
                              this.state.reservedAppointment.speciality1 == "EPUH") ? ReactUtility.toMultiLines(Localizer.get(Localizer.reserveTimeModalAppointmentDetailsConfirmPhoneTime,
                              this.state.reservedAppointmentId,
                              this.state.reservedAppointment?.specialistName,
                              this.state.reservedAppointment?.date,
                              this.state.reservedAppointment?.time))
                              :
                              ReactUtility.toMultiLines(Localizer.get(Localizer.reserveTimeModalAppointmentDetailsConfirm,
                                this.state.reservedAppointmentId,
                                this.state.reservedAppointment?.specialistName,
                                this.state.reservedAppointment?.date,
                                this.state.reservedAppointment?.time,
                                this.state.reservedAppointment?.specialistLocation))
                            }
                            <div className={styles.bottomButtonDiv}>
                                <Button label={Localizer.genericClose} type={ButtonType.Primary} onClick={() => this.closeAsync()}/>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.isloading && (
                        <Spinner global noDelay/>
                    )
                }

            </Modal>
        )
    }
}