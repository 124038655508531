import styles from "./DigiFenixFeature.module.scss"
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {IIconData} from "@/models/cms/DynamicPageResponse";
import FenixAppController from "@/pages/FenixAppController";

interface IDigiFenixFeatureParams {
	rank: number;
	title: string;
	content: string;
	icon: IIconData | null;
}

export const DigiFenixFeature = (params: IDigiFenixFeatureParams) => {
	return(
		<div className={styles.digiClinicFeature}>
			<div className={styles.featureIconContainer}>
				<div className={styles.featureIconDiv}>
					{params.icon ?
              <img src={params.icon?.data?.attributes?.url ?? "https://www.laakarikeskusfenix.fi/strapi-images/ikoni_fenix0_bc51beeb4a.png"} />
						:
						<img src="https://www.laakarikeskusfenix.fi/strapi-images/ikoni_fenix0_bc51beeb4a.png" />
					}
					
				</div>
			</div>
			<div className={styles.featureTextContainer}>
				<div className={styles.featureHeader}>
					{params.title ?? ''}
				</div>
				<div className={styles.featureContent}>
					<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{params.content ?? ''}</ReactMarkdown>
				</div>
			</div>
		</div>
	)
}