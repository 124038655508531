import React, {useEffect, useReducer, useRef, useState} from "react";
import styles from "./AppointmentBookingServicePointFilter.module.scss";
import {DateInput, Dropdown, DropdownAlign, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {IDoctor} from "@/models/interfaces/IDoctor";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
import Localizer from "@/localization/Localizer";

declare global {
    interface Date {
        older: (compare: Date) => Date;
        newer: (compare: Date) => Date;
    }
}

/**
 * Return the older date (less value)
 */
Date.prototype.older = function (compare: Date) {
    return this.getTime() < compare.getTime() ? this : compare;
};
/**
 * Return the newer date (more value)
 */
Date.prototype.newer = function (compare: Date) {
    return this.getTime() < compare.getTime() ? compare : this;
};
export interface AppointmentBookingServicePointFilterOnChange {
    medicalField: IMedicalField | null;
    specialist: IDoctor | null;
    location: INameExternalId | null;
    dateRangeStart: Date;
    dateRangeEnd: Date;
    timeRangeStart: Date;
    timeRangeEnd: Date;
    dateRangeStartEnd: [Date, Date];
}

export interface AppointmentBookingServicePointFilterProp {
    medicalFieldList: IMedicalField[];
    medicalFieldToListItem: (item: IMedicalField) => SelectListItem;
    listItemToTypeOfCare: (item: SelectListItem) => IMedicalField;
    defaultMedicalField?: IMedicalField | null;
    specialistList: IDoctor[];
    specialistToListItem: (item: IDoctor) => SelectListItem;
    listItemToSpecialist: (item: SelectListItem) => IDoctor;
    defaultSpecialist?: IDoctor | null;
    locationList: INameExternalId[];
    locationToListItem: (item: INameExternalId) => SelectListItem;
    listItemToLocation: (item: SelectListItem) => INameExternalId;
    defaultLocation?: INameExternalId | null;

    defaultDate: Date | null;

    onChange: (value: AppointmentBookingServicePointFilterOnChange) => void;
}

type OrderedDropDown = "location" | "specialist" | "medicalField";
export class BookingFilter {
    public filterFunctions = new Map<string, (items: any, filterBy: any) => any[]>();

    public dataSource = new Map<OrderedDropDown, any[]>();

    public activeFilter = new Map<OrderedDropDown, any>();

    addFilterFunction<T, Y>(item: OrderedDropDown, filterBy: OrderedDropDown, filter: (items: T[], filterBy: Y) => T[]) {
        this.filterFunctions.set([item, filterBy].join(), filter);
        return this;
    }
    getFilterFunction<T, Y>(item: OrderedDropDown, filterBy: OrderedDropDown): (items: T[], filterBy: Y) => T[] | null {
        return (this.filterFunctions.get([item, filterBy].join()) as (items: T[], filterBy: Y) => T[]) ?? null;
    }

    setSource<T>(item: OrderedDropDown, items: T[]) {
        this.dataSource.set(item, items);
        return this;
    }

    setActiveFilter<T>(key: OrderedDropDown, item: T | null | undefined) {
        if (item === null || item === undefined) {
            this.activeFilter.delete(key);
        } else {
            this.activeFilter.set(key, item);
        }
        return this;
    }

    getActiveFilter<T>(key: OrderedDropDown): T | null {
        return this.activeFilter.get(key) ?? null;
    }

    getFilteredValues<T>(orderedFilterKeys: OrderedDropDown[]) {
        const toFilterMap = new Map(this.dataSource);

        orderedFilterKeys.forEach((filterBy, filterByIndex) => {
            const activeFilterBy = this.activeFilter.get(filterBy);
            //  Copy Data source
            // @ts-ignore
            for (const [toFilterMapKey, toFilterMapValue] of toFilterMap.entries()) {
                const filterFunction = this.getFilterFunction(toFilterMapKey, filterBy);
                const filterFunctionOutput: unknown[] = filterFunction ? filterFunction(toFilterMapValue, activeFilterBy) ?? [] : [];

                const shouldSkip: boolean = (() => {
                    const toFilterMapKeyIndex = orderedFilterKeys.indexOf(toFilterMapKey);
                    const sameKey = toFilterMapKey === filterBy;
                    const isFiltered = toFilterMapKeyIndex !== -1;
                    const higherOrder = toFilterMapKeyIndex < filterByIndex;
                    return sameKey || (isFiltered && higherOrder);
                })();

                if (!shouldSkip) {
                    toFilterMap.set(toFilterMapKey, filterFunctionOutput);
                }
            }
        });
        return toFilterMap;
    }

    getFilteredValuesFor<T>(orderedFilterKeys: OrderedDropDown[], key: OrderedDropDown): T[] | null {
        const output = this.getFilteredValues(orderedFilterKeys);
        return output.get(key) ?? null;
    }
}

export interface AppointmentBookingServicePointFilterState {
    bookingFilter: BookingFilter;
    orderedFilter: OrderedDropDown[];
    timeRangeStart: Date;
    timeRangeEnd: Date;
    dateRangeEnd: Date;
    dateRangeStart: Date;
}
export type AppointmentBookingServicePointFilterActions =
    | {
          type: "locationChanged";
          payload: INameExternalId | null;
      }
    | {
          type: "specialistChanged";
          payload: IDoctor | null;
      }
    | {
          type: "medicalFieldChanged";
          payload: IMedicalField | null;
      }
    | {
          type: "dateRangeStartValueChanged";
          payload: Date;
      }
    | {
          type: "dateRangeEndValueChanged";
          payload: Date;
      }
    | {
          type: "timeRangeStartValueChanged";
          payload: Date;
      }
    | {
          type: "timeRangeEndValueChanged";
          payload: Date;
      }
    | {
          type: "refreshFilteredData";
          payload: undefined;
      };
const getTodayDate = (defaultDate2: Date | null, hours = 0, min = 0) => {
    const date: Date = (defaultDate2 != null && Object.prototype.toString.call(defaultDate2) === '[object Date]') ? defaultDate2 : new Date();

    date.setHours(hours, min, 0, 0);
    return date;
};
const getTomorrowDate = (reference = new Date(), hours = 0, min = 0) => {
    const date = new Date(reference.getTime() + 1000 * 60 * 60 * 24);
    date.setHours(hours, min, 0, 0);
    return date;
};
const getYesterdayDate = (reference = new Date(), hours = 0, min = 0) => {
    const date = new Date(reference.getTime() - 1000 * 60 * 60 * 24);
    date.setHours(hours, min, 0, 0);
    return date;
};

const onDropdownStateChange = (
    props: AppointmentBookingServicePointFilterProp,
    prevState: AppointmentBookingServicePointFilterState,
    dropdown: OrderedDropDown | null,
    payload: IDoctor | INameExternalId | IMedicalField | null
): AppointmentBookingServicePointFilterState => {
    const newState: AppointmentBookingServicePointFilterState = {...prevState};

    newState.bookingFilter
        .setSource("location", props.locationList)
        .setSource("medicalField", props.medicalFieldList)
        .setSource("specialist", props.specialistList);

    if (dropdown) {
        newState.bookingFilter.setActiveFilter(dropdown, payload);

        {
            const filterIndex = newState.orderedFilter.indexOf(dropdown);
            if (filterIndex === -1) {
                // This filter is not added yet
                if (payload) {
                    newState.orderedFilter = [...newState.orderedFilter, dropdown];
                }
            } else if (filterIndex === 0) {
                // This was the first filter in the list.
                if (payload) {
                    newState.orderedFilter = [dropdown];
                } else {
                    newState.orderedFilter = [];
                }
            } else {
                // Reset all the filters after the current value.
                newState.orderedFilter.splice(filterIndex, newState.orderedFilter.length - filterIndex);
                if (payload) {
                    newState.orderedFilter = [...newState.orderedFilter, dropdown];
                }
            }
        }
    }
    {
        if (newState.orderedFilter.indexOf("medicalField") === -1) {
            newState.bookingFilter.setActiveFilter("medicalField", null);
        }
        if (newState.orderedFilter.indexOf("location") === -1) {
            newState.bookingFilter.setActiveFilter("location", null);
        }
        if (newState.orderedFilter.indexOf("specialist") === -1) {
            newState.bookingFilter.setActiveFilter("specialist", null);
        }
    }

    return newState;
};
export const AppointmentBookingServicePointFilter = (props: AppointmentBookingServicePointFilterProp) => {
    const locationRef = useRef<Dropdown<INameExternalId> | null>(null);
    const specialistRef = useRef<Dropdown<IDoctor> | null>(null);
    const medicalFieldRef = useRef<Dropdown<IMedicalField> | null>(null);
    const [DATERANGE_START_MIN] = useState<Date>(getTodayDate(props.defaultDate));
    const [DATERANGE_END_MIN] = useState<Date>(getTodayDate(props.defaultDate));
    const [TIMERANGE_START_MINMAX] = useState<[Date, Date]>([getTodayDate(null, 8), getTodayDate(null, 20)]);
    const [TIMERANGE_END_MINMAX] = useState<[Date, Date]>([getTodayDate(null, 8), getTodayDate(null, 20)]);
    const [state, setState] = useReducer(
        (
            prevState: AppointmentBookingServicePointFilterState,
            action: AppointmentBookingServicePointFilterActions
        ): AppointmentBookingServicePointFilterState => {
            const {type, payload} = action;

            switch (type) {
                case "specialistChanged":
                    return onDropdownStateChange(props, prevState, "specialist", payload);
                case "medicalFieldChanged":
                    return onDropdownStateChange(props, prevState, "medicalField", payload);
                case "locationChanged":
                    return onDropdownStateChange(props, prevState, "location", payload);
                case "refreshFilteredData":
                    return onDropdownStateChange(props, prevState, null, null);
                case "dateRangeStartValueChanged":
                    return {
                        ...prevState,
                        dateRangeStart: payload.older(prevState.dateRangeEnd),
                        dateRangeEnd: payload.newer(prevState.dateRangeEnd)
                    };
                case "dateRangeEndValueChanged":
                    return {
                        ...prevState,
                        dateRangeStart: payload.older(prevState.dateRangeStart),
                        dateRangeEnd: payload.newer(prevState.dateRangeStart)
                    };
                case "timeRangeStartValueChanged":
                    return {
                        ...prevState,
                        timeRangeStart: payload.older(prevState.timeRangeEnd),
                        timeRangeEnd: payload.newer(prevState.timeRangeEnd)
                    };
                case "timeRangeEndValueChanged":
                    return {
                        ...prevState,
                        timeRangeStart: payload.older(prevState.timeRangeStart),
                        timeRangeEnd: payload.newer(prevState.timeRangeStart)
                    };
                default:
                    return {...prevState};
            }
        },
        ((): AppointmentBookingServicePointFilterState => {
            return {
                bookingFilter: new BookingFilter()
                    .setSource("location", props.locationList)
                    .setSource("medicalField", props.medicalFieldList)
                    .setSource("specialist", props.specialistList)
                    .addFilterFunction<IDoctor, INameExternalId | null>("specialist", "location", (items, location) => {
                        return items.filter((item) => {
                            return location ? item.locationIds.includes(location.id) : false;
                        });
                    })
                    .addFilterFunction<IDoctor, IMedicalField>("specialist", "medicalField", (items, medicalField) => {
                        return items.filter((item) => {
                            return item.medicalFieldIds.includes(medicalField.id);
                        });
                    })
                    .addFilterFunction<INameExternalId, IDoctor>("location", "specialist", (items, specialists) => {
                        return items.filter((item) => {
                            return specialists.locationIds.includes(item.id);
                        });
                    })
                    .addFilterFunction<INameExternalId, IMedicalField>("location", "medicalField", (items, medicalField) => {
                        return items.filter((item) => {
                            return medicalField.locationIds.includes(item.id);
                        });
                    })
                    .addFilterFunction<IMedicalField, IDoctor>("medicalField", "specialist", (items, specialist) => {
                        return items.filter((item) => {
                            return specialist.medicalFieldIds.includes(item.id);
                        });
                    })
                    .addFilterFunction<IMedicalField, INameExternalId | null>("medicalField", "location", (items, location) => {
                        return items.filter((item) => {
                            return location ? item.locationIds.includes(location.id) : false;
                        });
                    })
                    .setActiveFilter("specialist", props.defaultSpecialist)
                    .setActiveFilter("medicalField", props.defaultMedicalField)
                    .setActiveFilter("location", props.defaultLocation),
                orderedFilter: (() => {
                    const list: OrderedDropDown[] = [];
                    if (props.defaultMedicalField) {
                        list.push("medicalField");
                    }
                    if (props.defaultSpecialist) {
                        list.push("specialist");
                    }
                    if (props.defaultLocation) {
                        list.push("location");
                    }
                    return list;
                })(),
                dateRangeStart: getTodayDate(props.defaultDate),
                dateRangeEnd: getTomorrowDate(getTodayDate(props.defaultDate)),
                timeRangeStart: getTodayDate(null, 8),
                timeRangeEnd: getTodayDate(null, 20)
            };
        })(),
        undefined as any
    );

    const getMedicalFields = (): IMedicalField[] => {
        return (
            state.bookingFilter.getFilteredValuesFor<IMedicalField>(state.orderedFilter, "medicalField") ??
            props.medicalFieldList ??
            []
        );
    }

    const getMedicalField = (): IMedicalField | undefined => {
        return state.bookingFilter.getActiveFilter<IMedicalField>("medicalField") ?? undefined;
    }

    const setMedicalField = async (value: IMedicalField | null): Promise<void> => {
        let location: INameExternalId | null = getLocation() ?? null;

        await setState({type: "medicalFieldChanged", payload: value});
        //state.bookingFilter.setActiveFilter("medicalField", value);

        if (value != null) {
            const locations: INameExternalId[] = props.locationList.where(item => value.locationIds.contains(item.id));
            location = locations.firstOrDefault(item => item.id == location?.id);

            if ((location == null) && (locations.length == 1)) {
                location = locations.first();
            }

            await setLocation(location);
        }
    }

    const getSpecialists = (): IDoctor[] => {
        return (
            state.bookingFilter.getFilteredValuesFor<IDoctor>(state.orderedFilter, "specialist") ??
            props.specialistList ??
            []
        );
    }

    const getSpecialist = (): IDoctor | undefined => {
        return state.bookingFilter.getActiveFilter<IDoctor>("specialist") ?? undefined;
    }

    const setSpecialist = async (value: IDoctor | null): Promise<void> => {
        await setState({type: "specialistChanged", payload: value});
        //state.bookingFilter.setActiveFilter("specialist", value);
    }

    const getLocations = (): INameExternalId[] => {
        return (
            state.bookingFilter.getFilteredValuesFor<INameExternalId>(state.orderedFilter, "location") ??
            props.locationList
        );
    }

    const getLocation = (): INameExternalId | undefined => {
        return state.bookingFilter.getActiveFilter<INameExternalId>("location") ?? undefined;
    }

    const setLocation = async (value: INameExternalId | null): Promise<void> => {
        await setState({ type: "locationChanged", payload: value });
        //state.bookingFilter.setActiveFilter("location", value);
    }

    useEffect(() => {
        const onChangeValue: AppointmentBookingServicePointFilterOnChange = {
            medicalField: state.bookingFilter.getActiveFilter("medicalField"),
            specialist: state.bookingFilter.getActiveFilter("specialist"),
            location: state.bookingFilter.getActiveFilter("location"),
            dateRangeStart: state.dateRangeStart,
            dateRangeEnd: state.dateRangeEnd,
            timeRangeStart: state.timeRangeStart,
            timeRangeEnd: state.timeRangeEnd,
            dateRangeStartEnd: [state.dateRangeStart, state.dateRangeEnd]
        };
        props.onChange(onChangeValue);
    }, [
        state.bookingFilter.getActiveFilter("medicalField"),
        state.bookingFilter.getActiveFilter("specialist"),
        state.bookingFilter.getActiveFilter("location"),
        state.timeRangeStart,
        state.timeRangeEnd,
        state.dateRangeStart,
        state.dateRangeEnd
    ]);

    useEffect(() => {
        setState({
            type: "refreshFilteredData",
            payload: undefined
        });
    }, [props.medicalFieldList, props.specialistList, props.locationList]);
    return (
        <div className={styles.container}>

            <Dropdown<IMedicalField>
                align={DropdownAlign.Left}
                ref={medicalFieldRef}
                className={styles.dropdownInput}
                nothingSelectedText={Localizer.appointmentPageMedicalFieldNothingSelected}
                label={Localizer.appointmentPageSpecialty}
                items={getMedicalFields()}
                selectedItem={getMedicalField()}
                transform={(item) => props.medicalFieldToListItem(item)}
                onChange={(_, value) => setMedicalField(value)}
            />

            <Dropdown<IDoctor>
                ref={specialistRef}
                align={DropdownAlign.Left}
                nothingSelectedText={Localizer.appointmentPageDoctorNothingSelected}
                className={styles.dropdownInput}
                label={Localizer.appointmentPageSpecialistInCharge}
                items={getSpecialists()}
                selectedItem={getSpecialist()}
                transform={(item) => props.specialistToListItem(item)}
                onChange={(_, value) => setSpecialist(value)}
            />

            <Dropdown<INameExternalId>
                ref={locationRef}
                className={styles.dropdownInput}
                nothingSelectedText={Localizer.appointmentPageLocationNothingSelected}
                label={Localizer.homePageHeaderLocation}
                items={getLocations()}
                selectedItem={getLocation()}
                transform={(item) => props.locationToListItem(item)}
                onChange={(_, value) => setLocation(value)}
            />

            <div className={styles.groupedInputContainer}>
                <label htmlFor="startDate">{Localizer.appointmentPageSearchForTimesBetween}</label>
                <div className={styles.groupedInput}>
                    <DateInput
                        id="startDate"
                        minDate={DATERANGE_START_MIN}
                        value={state.dateRangeStart}
                        onChange={async (value) => {
                            setState({
                                type: "dateRangeStartValueChanged",
                                payload: value
                            });
                        }}
                    />
                    <DateInput
                        minDate={DATERANGE_END_MIN}
                        value={state.dateRangeEnd}
                        onChange={async (value) =>
                            setState({
                                type: "dateRangeEndValueChanged",
                                payload: value
                            })
                        }
                    />
                </div>
            </div>
            <div className={styles.groupedInputContainer}>
                <label htmlFor="startTime">{Localizer.appointmentPageChooseTimeframe}</label>
                <div className={styles.groupedInput}>
                    <DateInput
                        showTime
                        showOnlyTime
                        timeCaption={Localizer.appointmentBookingServicePointFilterTime}
                        id="startTime"
                        minTime={TIMERANGE_START_MINMAX[0]}
                        maxTime={TIMERANGE_START_MINMAX[1]}
                        value={state.timeRangeStart}
                        onChange={async (value) =>
                            setState({
                                type: "timeRangeStartValueChanged",
                                payload: value
                            })
                        }
                    />
                    <DateInput
                        showTime
                        showOnlyTime
                        timeCaption={Localizer.appointmentBookingServicePointFilterTime}
                        minTime={TIMERANGE_END_MINMAX[0]}
                        maxTime={TIMERANGE_END_MINMAX[1]}
                        value={state.timeRangeEnd}
                        onChange={async (value) =>
                            setState({
                                type: "timeRangeEndValueChanged",
                                payload: value
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};
