import styles from "./DigiFenixStep.module.scss"
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IDigiClinicStepParams {
	rank: number;
	header: string;
	content: string;
}

export const DigiFenixStep = (params: IDigiClinicStepParams) => {
	return(
		<div className={styles.digiClinicStep}>
			<div className={styles.stepNumberContainer}>
				<div className={styles.stepNumberDiv}>
					{params.rank}
				</div>
			</div>
			<div className={styles.stepTextContainer}>
				<div className={styles.stepHeader}>
					<p>{params.header ?? ''}</p>
				</div>
				<div className={styles.stepContent}>
					<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{params.content ?? ''}</ReactMarkdown>
				</div>
			</div>
		</div>
	)
}