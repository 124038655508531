import React, {useEffect, useReducer, useRef, useState} from "react";
import styles from "./SearchCategoryFilter.module.scss";
import {Dropdown, DropdownAlign, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import Localizer from "@/localization/Localizer";
import {ISearchCategory} from "@/models/interfaces/ISearchCategory";
import {SearchFilter} from "@/pages/Search/Search";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
export interface SearchFilterOnChange {
	searchCategory: IMedicalField | null;
}

export interface SearchFilterProp {
	onChange: (value: SearchFilterOnChange) => void;
	initialCategory?: ISearchCategory;
}


export interface SearchFilterState {
	searchFilter: SearchFilter;
	orderedFilter: SearchOrderedDropDown[];
}
export type SearchFilterActions = {	
	type: "filterCategoryChanged";
	payload: ISearchCategory | null;
};


const onDropdownStateChange = (
	props: SearchFilterProp,
	prevState: SearchFilterState,
	dropdown: SearchOrderedDropDown | null,
	payload: ISearchCategory | null
): SearchFilterState => {
	const categoryList: ISearchCategory[] = [
		{
			id: "service",
			name: Localizer.topNavServices
		},
		{
			id: "medical-field",
			name: Localizer.searchPagemedicalFields
		},
		{
			id: "clinic",
			name: Localizer.topNavClinics
		},
		{
			id: 'service-voucher',
			name: Localizer.searchPagevouchers
		},
		{
			id: "doctor",
			name: Localizer.topNavDoctors
		},
		{
			id: "location",
			name: Localizer.homePageHeaderLocation
		},
		{
			id: 'blog-post',
			name: Localizer.blogPageTitle
		},
		{
			id: 'occupational-health',
			name: Localizer.occupationalHealthPageTitle
		},
		{
			id: 'job-posting',
			name: Localizer.topNavJobs
		}
	]
	const newState: SearchFilterState = {...prevState};
	
	newState.searchFilter
		.setSource("searchCategory", categoryList)

	if (dropdown) {
		newState.searchFilter.setActiveFilter(dropdown, payload);

		{
			const filterIndex = newState.orderedFilter.indexOf(dropdown);
			if (filterIndex === -1) {
				// This filter is not added yet
				if (payload) {
					newState.orderedFilter = [...newState.orderedFilter, dropdown];
				}
			} else if (filterIndex === 0) {
				// This was the first filter in the list.
				if (payload) {
					newState.orderedFilter = [dropdown];
				} else {
					newState.orderedFilter = [];
				}
			} else {
				// Reset all the filters after the current value.
				newState.orderedFilter.splice(filterIndex, newState.orderedFilter.length - filterIndex);
				if (payload) {
					newState.orderedFilter = [...newState.orderedFilter, dropdown];
				}
			}
		}
	}
	{
		if (newState.orderedFilter.indexOf("searchCategory") === -1) {
			newState.searchFilter.setActiveFilter("searchCategory", null);
		}
	}

	return newState;
};

type SearchOrderedDropDown = "searchCategory";
export const SearchCategoryFilter = (props: SearchFilterProp) => {
	const useIsMount = () => {
		const isMountRef = useRef(true);
		useEffect(() => {
			isMountRef.current = false;
		}, []);
		return isMountRef.current;
	};
	const isMount = useIsMount();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const initialCategory = urlParams.get('category');
		if(initialCategory){
			state.searchFilter.setActiveFilter("searchCategory", categoryList.find(category => category.id == initialCategory));
		}
		else {
			if(props.initialCategory){
				state.searchFilter.setActiveFilter("searchCategory", props.initialCategory);
			}
		}
	}, [])
	
	useEffect(() => {
		if(props.initialCategory){
			if(state.searchFilter.getActiveFilter('searchCategory') !== props.initialCategory){
				state.searchFilter.setActiveFilter('searchCategory', props.initialCategory);
			}
		}
	}, [props.initialCategory])
	
	const categoryList: ISearchCategory[] = [
		{
			id: "service",
			name: Localizer.topNavServices
		},
		{
			id: "medical-field",
			name: Localizer.searchPagemedicalFields
		},
		{
			id: "clinic",
			name: Localizer.topNavClinics
		},
		{
			id: 'service-voucher',
			name: Localizer.searchPagevouchers
		},
		{
			id: "doctor",
			name: Localizer.topNavDoctors
		},
		{
			id: "location",
			name: Localizer.homePageHeaderLocation
		},
		{
			id: 'blog-post',
			name: Localizer.blogPageTitle
		},
		{
			id: 'occupational-health',
			name: Localizer.occupationalHealthPageTitle
		},
		{
			id: 'job-posting',
			name: Localizer.topNavJobs
		}
	]
	
	const [state, setState] = useReducer(
		(
			prevState: SearchFilterState,
			action: SearchFilterActions
		): SearchFilterState => {
			const {type, payload} = action;

			switch (type) {
				case "filterCategoryChanged":
					return onDropdownStateChange(props, prevState, "searchCategory", payload);
				default:
					return {...prevState};
			}
		},
		((): SearchFilterState => {
			return {
				searchFilter: new SearchFilter()
					.setSource("searchCategory", categoryList),
				orderedFilter: (() => {
					const list: SearchOrderedDropDown[] = [];

					return list;
				})(),
			};
		})(),
		undefined as any
	);
	

	useEffect(() => {
		if(!isMount){
			const onChangeValue: SearchFilterOnChange = {
				searchCategory: state.searchFilter.getActiveFilter("searchCategory"),
			}
			props.onChange(onChangeValue);
		}
	}, [
		state.searchFilter.getActiveFilter("searchCategory"),
	]);
	

	const switchFilterCategory = async (value: any) => {
		state.searchFilter.setActiveFilter("searchCategory", value);
	}

	const getSearchCategory = (): ISearchCategory | undefined => {
		return state.searchFilter.getActiveFilter<ISearchCategory>("searchCategory") ?? undefined;
	}

	const setFilterCategory = async (value: ISearchCategory | null): Promise<void> => {
		await setState({ type: "filterCategoryChanged", payload: value });
	}

	const getSearchCategories = (): ISearchCategory[] => {
		return (
			state.searchFilter.getFilteredValuesFor<ISearchCategory>(state.orderedFilter, "searchCategory") ??
			[]
		);
	}

	const toSelectListItem = (item: ISearchCategory) => {
		const selectedItem = new SelectListItem();
		selectedItem.ref = item;
		selectedItem.value = item.id;
		selectedItem.text = item.name;
		return selectedItem;
	}
	
	return (
		<div className={styles.container}>
			<Dropdown<ISearchCategory>
				align={DropdownAlign.Left}
				className={styles.dropdownInput}
				nothingSelectedText={Localizer.searchPagenoFilterSelected}
				items={getSearchCategories()}
				selectedItem={getSearchCategory()}
				transform={(item) => toSelectListItem(item)}
				onChange={(_, value) => setFilterCategory(value)}
			/>
			
		</div>
	);
};
