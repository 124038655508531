import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from "./TopNavSearch.module.scss";
import {ApiProvider, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import SearchResponse from "@/models/server/responses/SearchResponse";
import {DynamicPageAttributes} from "@/models/cms/DynamicPageResponse";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import {Icon, IconStyle} from "@reapptor-apps/reapptor-react-components";
import {Oval} from "react-loader-spinner";



interface TopNavSearchProps {
	
}

interface CategorizedSearchResults {
	totalHits: number;
	results: { title: string; indexUid: string; hits: number; }[];
}
export const TopNavSearch = (props: TopNavSearchProps) => {
	const [query, setQuery] = useState('');
	const [debouncedQuery, setDebouncedQuery] = useState('');
	const [results, setResults] = useState<CategorizedSearchResults | null>(null);
	const [visible, setVisible] = useState(false);
	const screenWidth = useWindowWidth();
	const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
	const [meiliSearchDown, setMeiliSearchDown] = useState(false);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	
	const useOutsideClick = (callback: () => void) => {
		const ref = useRef<HTMLDivElement>(null);

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent | TouchEvent) => {
				if (ref.current && !ref.current.contains(event.target as Node)) {
					event.preventDefault();
					callback();
				}
			};
			document.addEventListener('mouseup', handleClickOutside);
			document.addEventListener('touchend', handleClickOutside);


			return () => {
				document.removeEventListener('mouseup', handleClickOutside);
				document.removeEventListener('touchend', handleClickOutside);
			};
		}, [callback]);

		return ref;
	};

	function useWindowWidth() {
		const [width, setWidth] = useState(0);
		useLayoutEffect(() => {
			function updateWidth() {
				setWidth(window.innerWidth);
			}
			window.addEventListener('resize', updateWidth);
			updateWidth();
			return () => window.removeEventListener('resize', updateWidth);
		}, []);
		return width;
	}
	
	
	const resetSearchResults = () => {
		setResults(null);
	}
	
	const hide = () => {
		setVisible(false);
	}
	
	const show = () => {
		setVisible(true);
	}
	
	const mobileSearchRef = useOutsideClick(() => {
		closeMobileModal();
	})
	
	const searchRef = useOutsideClick(() => {
		setVisible(!visible);
	})
	
	const closeMobileModal = () => {
		setMobileSearchVisible(false);
		setQuery('');
	}
	
	
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedQuery(query);
		}, 800);
		return () => clearTimeout(timeoutId);
	}, [query, 800])
	
	useEffect(() => {
		if(!debouncedQuery){
			resetSearchResults();
		}
		if(debouncedQuery){
			searchFromStrapi(debouncedQuery);
		}
	}, [debouncedQuery])
	
	const categorizeSearchResults = (searchResults: SearchResponse) => {
		const getTitle = (uid: string) => {
			switch(uid){
				case 'doctor':
					return Localizer.topNavDoctors;
				case 'blog-post':
					return Localizer.blogPageTitle;
				case 'clinic': 
					return Localizer.topNavClinics;
				case 'company':
				case 'company-info':
					return Localizer.topNavCompany;
				case 'job':
				case 'job-posting':
					return Localizer.topNavJobs;
				case 'location':
					return Localizer.topNavLocations;
				case 'medical-field':
					return Localizer.searchPagemedicalFields;
				case 'occupational-health':
					return Localizer.occupationalHealthPageTitle;
				case 'service': 
					return Localizer.topNavServices;
				case 'service-voucher': 
					return Localizer.searchPagevouchers;
				default:
					return '...'
			}
		}
		
		const categories = [...new Set(searchResults.hits.map(hit => hit._federation?.indexUid!))];
		var resultArray: { title: string; indexUid: string; hits: number; }[] = [];
		categories.forEach(category => {
			const categoryObject = {
				title: getTitle(category),
				indexUid: category,
				hits: searchResults.hits.filter(hit => hit._federation?.indexUid == category).length
			};
			resultArray.push(categoryObject);
		})
		return resultArray;
	}
	
	const searchFromStrapi = async (query: string) => {
		if(query){
			setMeiliSearchDown(false);
			setShowLoadingSpinner(true);
			const searchResults: SearchResponse = await ApiProvider.getAsync(`/api/Application/Search?query=${query}&limit=500&source=TopNav`, null);
			if(searchResults.failed){
				setShowLoadingSpinner(false);
				setMeiliSearchDown(true);
			}
			else{
				const categorizedResults = categorizeSearchResults(searchResults);
				setShowLoadingSpinner(false);
				await setResults({results: categorizedResults, totalHits: searchResults.totalHits});
			}
			show();
		}
	}
	const redirectToSearchPage = async (e: any, category?: string) => {
		e.stopPropagation();
		e.currentTarget.blur();
		hide();
		if(screenWidth <= 768){
			setMobileSearchVisible(false);
		}
		if(query){
			const originalQuery = query;
			if(category){
				setQuery('');
				await PageRouteProvider.redirectAsync(PageDefinitions.searchRoute(originalQuery, category))
			}
			else{
				setQuery('');
				await PageRouteProvider.redirectAsync(PageDefinitions.searchRoute(originalQuery))
			}
		}
		else {
			setQuery('');
			await PageRouteProvider.redirectAsync(PageDefinitions.searchRoute())
		}
	}
	
	const openMobileSearch = () => {
		setResults(null);
		setMobileSearchVisible(!mobileSearchVisible);
	}
	
	const showSearch = () => {
		setVisible(true);
	}

	return (
		<>
			{screenWidth > 768 ?
				<div className={styles.searchContainerDiv}  >
						<div className={styles.searchBarDiv}>
							<div className={styles.inputContainer}>
								<div className={styles.inputDiv}>
									
									<input
										onClick={() => showSearch()}
										className={styles.searchInput}
										type="text"
										value={query}
										onChange={(event) => setQuery(event.target.value)}
										placeholder={Localizer.searchpagesearch}/>
									<button onClick={(e) => redirectToSearchPage(e)} className={styles.searchButton}><Icon name="search" />{Localizer.searchpagesearch}</button>
									
								</div>
								{showLoadingSpinner &&
										<div className={styles.loadingContainer}>
												<Oval
                            visible={true}
                            height="50"
                            width="50"
                            color="#F28325"
                            secondaryColor="#a95c1a"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
												/>
										</div>}
								{(meiliSearchDown && visible) &&
                    <div className={styles.searchResultsContainer}>
                        <div className={styles.searchResultsDiv}>
                            <div className={styles.noResultsDiv}>
                                <h5>{Localizer.searchPageserviceError}</h5>
                            </div>
												</div>
										</div>
								}
								{(results && visible) &&
									<div className={styles.searchResultsContainer} ref={searchRef}>
										
                        <div className={styles.searchResultsDiv}>
													{results.totalHits == 0 &&
                              <div className={styles.noResultsDiv}>
																<h5>{Localizer.searchPagenoResults}</h5>
                              </div>
													}
													{results.results.length > 0 &&
														<div>
														{results.results.map(searchCategory => {
															return (
																<div className={styles.searchResultsRow} key={searchCategory.indexUid}>
																	<div className={styles.searchResultHitText} onClick={(e) => redirectToSearchPage(e, searchCategory.indexUid)}>
																		<span className={styles.categorySpan}>{searchCategory.title}</span>
																		<span className={styles.categoryNumberOfHitsSpan}>({searchCategory.hits} {Localizer.searchPagehits})</span>
																	</div>
																	<div className={styles.hitButtonContainer}>
																		<button className={styles.hitButton} onClick={(e) => redirectToSearchPage(e, searchCategory.indexUid)}>
																			<Icon 			name={"chevron-right"}
																								 style={IconStyle.Light}
																			/>
																		</button>
																		
																	</div>
																</div>
															)
														})}
                                <div className={styles.searchResultsRow}>
                                    <div className={styles.searchResultHitText} onClick={(e) => redirectToSearchPage(e)}>
                                        <span className={styles.categorySpan}>{Localizer.searchPageallResults}</span>
																				<span className={styles.categoryNumberOfHitsSpan}>({results.totalHits} {Localizer.searchPagehits})</span>
                                    </div>
                                    <div className={styles.hitButtonContainer}>
																				<button className={styles.hitButton} onClick={(e) => redirectToSearchPage(e)}>
                                            <Icon 			name={"chevron-right"}
                                                       style={IconStyle.Light}
                                            />
																				</button>
                                        
                                    </div>
                                </div>	
														</div>
													}
                        </div>
										
									</div>
								}
									
								
							</div>
								

						</div>
						
				</div>
				:
				<>
					<div className={styles.mobileSearchButtonDiv}>
						<button onClick={() => openMobileSearch()} className={styles.mobileSearchButton}>
							<Icon 			name={"search"}
												 style={IconStyle.Light} 
							/>
						</button>
					</div>
					{mobileSearchVisible &&
              <div id="mobileSearchContainer" ref={mobileSearchRef} className={styles.mobileSearchContainer}>
									<div className={styles.mobileSearchCloseRow}>
											<button className={styles.mobileSearchCloseButton} onClick={() => closeMobileModal()}>{Localizer.genericClose}</button>
									</div>
									<div className={styles.mobileSearchHeaderDiv}>
										<h4>{Localizer.searchPagesearchFromApplication}</h4>
									</div>
									<div className={styles.mobileSearchInputContainer}>
                      <div className={styles.mobileSearchInputDiv}>
                          <input
                              className={styles.mobileSearchInput}
                              type="text"
                              onChange={(event) => setQuery(event.target.value)}
                              placeholder="Hae"/>
                          <button onClick={(e) => redirectToSearchPage(e)} className={styles.mobileSearchButton}><Icon name="search" />Hae</button>
                      </div>
											
									</div>
									<div className={styles.mobileSearchResultsContainer}>
										{showLoadingSpinner &&
                        <div className={styles.mobileLoadingContainer}>
                            <Oval
                                visible={true}
                                height="50"
                                width="50"
                                color="#F28325"
                                secondaryColor="#a95c1a"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
										}
										{meiliSearchDown &&
                        <div className={styles.searchResultsContainer}>
                            <div className={styles.searchResultsDiv}>
                                <div className={styles.noResultsDiv}>
                                    <h5>{Localizer.searchPageserviceError}</h5>
                                </div>
                            </div>
                        </div>
										}
										{(results) &&
                        <div className={styles.searchResultsContainer}>

                            <div className={styles.searchResultsDiv}>
															{results.totalHits == 0 &&
                                  <div className={styles.noResultsDiv}>
                                      <h5>{Localizer.searchPagenoResults}</h5>
                                  </div>
															}
															{results.results.length > 0 &&
                                  <>
																		{results.results.map(searchCategory => {
																			return (
																				<div className={styles.searchResultsRow} key={searchCategory.indexUid}>
																					<div className={styles.mobileSearchResultHitText} onClick={(e) => redirectToSearchPage(e, searchCategory.indexUid)}>
																						<span className={styles.mobileCategorySpan}>{searchCategory.title}</span>
																						<span>({searchCategory.hits} {Localizer.searchPagehits})</span>
																					</div>
																					<div className={styles.hitButtonContainer}>
																						<button className={styles.hitButton} onClick={(e) => redirectToSearchPage(e, searchCategory.indexUid)}>
																							<Icon 			name={"chevron-right"}
																												 style={IconStyle.Light}
																							/>
																						</button>

																					</div>
																				</div>
																			)
																		})}
                                      <div className={styles.searchResultsRow}>
                                          <div className={styles.mobileSearchResultHitText} onClick={(e) => redirectToSearchPage(e)}>
                                              <span className={styles.mobileCategorySpan}>{Localizer.searchPageallResults}</span>
																							<span>({results.totalHits} {Localizer.searchPagehits})</span>
                                          </div>
                                          <div className={styles.hitButtonContainer}>
                                              <button className={styles.hitButton} onClick={(e) => redirectToSearchPage(e)}>
                                                  <Icon 			name={"chevron-right"}
                                                             style={IconStyle.Light}
                                                  />
                                              </button>

                                          </div>
                                      </div>
                                  </>
															}
                            </div>

                        </div>
										}
											
									</div>
              </div>
					}
					
				</>
			}
		</>
	);
};