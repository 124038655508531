import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
	Button,
	ButtonType,
	Form, IAccordionClassNames,
	PageContainer,
	PageHeader,
} from "@reapptor-apps/reapptor-react-components";
import ReactMarkdown from "react-markdown";
import Localizer from "@/localization/Localizer";
import {DynamicPageData, DynamicPageResponseSingleType} from "@/models/cms/DynamicPageResponse";
import remarkGfm from "remark-gfm";
import { Accordion, Stack, Avatar } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import 'rsuite/Accordion/styles/index.css';
import styles from "./DigiFenix.module.scss";
import {DigiFenixStep} from "@/components/DigiFenixStep/DigiFenixStep";
import {DigiFenixFeature} from "@/components/DigiFenixFeature/DigiFenixFeature";
import { FaChevronDown } from 'react-icons/fa';
import PlayStoreBadge from '../../images/Google_Play_Store_badge_EN.svg';
import AppStoreBadge from '../../images/Download_on_the_App_Store_Badge.svg';
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

export interface IDigiFenixParams {}
interface IDigiFenixState {
	data: DynamicPageData | null;
}


export default class DigiFenix extends AnonymousPage {

	Header = (props: any) => {
		const { avatarUrl, title, isBasicAccordion, ...rest } = props;
		return (
			<Stack {...rest} spacing={50} direction="row" alignItems="flex-start">
				{avatarUrl ?
            <img src={avatarUrl} className={styles.headerIcon}/>
						:
						<img src={"https://www.laakarikeskusfenix.fi/strapi-images/ikoni_fenix0_bc51beeb4a.png"} className={styles.headerIcon}/>
				}
					<div className={isBasicAccordion ? styles.basicAccordionHeader : styles.mainAccordionHeader}>{title}</div>
			</Stack>
		);
	};
	

	static accordionClassNames : IAccordionClassNames = {
		headerContainer: 'digiAccordionHeaderContainer'
	}

	state: IDigiFenixState = {
		data: null
	};


	public formRef: React.RefObject<Form> = React.createRef();

	async initializeAsync(): Promise<void> {
		await super.initializeAsync();
		const response: DynamicPageResponseSingleType = await this.getAsync(
			"/api/Application/GetDynamicPageContent?contentType=digi-clinic-page&populateDigiClinic=true"
		);
		await this.setState({data: response.data});
		window.scrollTo(0, 0);
	}

	async redirectToUrl(url: string) {
		if(url){
			window.open(url, '_blank')?.focus();
		}
	}


	public render(): React.ReactNode {

		return (
			<PageContainer fullWidth className={styles.pageContainer}>
				<PageHeader className={styles.pageHeader} title={Localizer.digiFenixPageTitle}/>
				<div className={styles.pageContent}>
					<div className={styles.fluidContent}>
						{this.state.data && (
							<>
								<div className={styles.headerDiv}>
									<h2>{this.state.data?.attributes?.title}</h2>
									<hr />
									<ReactMarkdown>{this.state.data?.attributes?.content}</ReactMarkdown>
									{this.state.data?.attributes?.landingPageIntro &&
											<div className={styles.introDiv}>
													<div className={styles.introTextDiv}>
                              <h4>{this.state.data?.attributes?.landingPageIntro.title}</h4>
                              <ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{this.state.data.attributes.landingPageIntro.content ?? ''}</ReactMarkdown>
														{this.state.data?.attributes?.landingPageIntro.steps &&
                                <div>
																	{this.state.data?.attributes?.landingPageIntro.steps.map(step => {
																		return(
																			<DigiFenixStep key={step.header} rank={step.rank} header={step.header} content={step.content} />
																		)
																	})}
                                </div>
														}
														<div className={styles.introLinksDiv}>
																<div className={styles.desktopLinkDiv}>
                                    <Button label={Localizer.digiFenixPageWebVersionButton}
																						className={styles.desktopLinkButton}
                                            type={ButtonType.Orange}
                                            onClick={() => this.redirectToUrl(this.state.data?.attributes.landingPageIntro?.desktopLink ?? '')}
                                    />
																</div>
																<div className={styles.appLinksDiv}>
																		<div className={styles.appLinkButtonDiv}>
                                        <img onClick={() => this.redirectToUrl(this.state.data?.attributes.GooglePlayStoreLink as string ?? '')} src={PlayStoreBadge} alt="PlayStoreLink"/>
																		</div>
																		<div className={styles.appLinkButtonDiv}>
                                        <img onClick={() => this.redirectToUrl(this.state.data?.attributes.AppStoreLink as string ?? '')} src={AppStoreBadge} alt="AppStoreLink"/>
																		</div>
																</div>
														</div>
													</div>
												{this.state.data?.attributes.landingPageIntro.image &&
                            <div className={styles.introImgDiv}>
																<img src={this.state.data?.attributes.landingPageIntro.image.data.attributes.url} alt="Landing page image" />
                            </div>
												}
											</div>
									}
									{!this.state.data.attributes.landingPageIntro &&
                      <div className={styles.appStoreDiv}>
												{(this.state.data?.attributes.GooglePlayStoreLink && !this.state.data?.attributes.landingPageIntro) &&
                            <img onClick={() => this.redirectToUrl(this.state.data?.attributes.GooglePlayStoreLink as string ?? '')} src={PlayStoreBadge} alt="PlayStoreLink"/>
												}
												{(this.state.data?.attributes.AppStoreLink && !this.state.data?.attributes.landingPageIntro) &&
                            <img onClick={() => this.redirectToUrl(this.state.data?.attributes.AppStoreLink as string ?? '')} src={AppStoreBadge} alt="AppStoreLink"/>
												}
                      </div>
									}
								</div>
								{this.state.data.attributes?.digiAccordions && 
										<div className={styles.digiAccordionsDiv}>
											{this.state.data.attributes?.digiAccordions.map(accordion => {
												return (
													<div key={accordion.title}>
														{accordion.linkTo ? 
															<div className={styles.linkHeader}>
																<div className="rs-panel-header rs-heading linkHeader" onClick={() => window.location.href = accordion.linkTo}>
																	<this.Header avatarUrl={accordion.icon?.data?.attributes?.url}
																	title={accordion.title}/>
																</div>
															</div>
														:
															<Accordion>
																<Accordion.Panel
																	caretAs={FaChevronDown}
																	defaultExpanded={false}
																	header={
																		<this.Header
																			avatarUrl={accordion.icon?.data?.attributes?.url}
																			title={accordion.title}
																		/>
																	}
																	eventKey={1}
																>
																	{accordion.contentHeader &&
                                      <div className={styles.headerDiv}>
                                          <h4>{accordion.contentHeader}</h4>
                                      </div>
																	}
																	{accordion.content &&
                                      <div className={styles.contentDiv}>
                                          <ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{accordion.content}</ReactMarkdown>
                                      </div>
																	}
																	{(accordion.steps && accordion.steps.length > 0) &&
                                      <>
																				{accordion.steps.map(step => {
																					return(
																						<DigiFenixStep key={step.header} rank={step.rank} header={step.header} content={step.content} />
																					)
																				})}
                                      </>
																	}
																	{(accordion.features && accordion.features.length > 0) &&
                                      <>
																				{accordion.features.map(feature => {
																					return(
																						<DigiFenixFeature key={feature.title} rank={feature.rank} title={feature.title} content={feature.content} icon={feature.icon} />
																					)
																				})}
                                      </>
																	}
																	{(accordion.accordions && accordion.accordions.length > 0) &&
                                      <>
																				{accordion.accordions.map(accord => {
																					return(
																						<Accordion bordered key={accord.title}>
																							<Accordion.Panel
																								caretAs={FaChevronDown}
																								bordered={true}
																								defaultExpanded={false}
																								header={
																									<this.Header
																										title={accord.title}
																										isBasicAccordion={true}
																									/>
																								}
																								eventKey={1}
																							>
																								<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{accord.content}</ReactMarkdown>
																							</Accordion.Panel>
																						</Accordion>
																					)
																				})}
                                      </>
																	}
																</Accordion.Panel>
															</Accordion>
														}
														
														
														{/*<Accordion header={accordion.title}
																			 autoCollapse={false}
																			 className={styles.digiAccordion}
																			 classNames={DigiClinic.accordionClassNames}
														>
															<ReactMarkdown className={styles.pricesTable} remarkPlugins={[remarkGfm]}>{accordion.content}</ReactMarkdown>
														</Accordion>*/}
													</div>
												)
											})}
										</div>
								}
							</>
						)}
					</div>
				</div>
			</PageContainer>
		);
	}
}