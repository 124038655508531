import React, {useEffect, useState} from "react";
import {ch, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Layout} from "@reapptor-apps/reapptor-react-components";
import TemplateAppConstants from "@/helpers/TemplateAppConstants";
import FenixAppController from "@/pages/FenixAppController";
import {Footer} from "@/components/Footer/Footer";

import appLogo from "./app-logo-white.png";
import companyLogo from "./company-logo.png";
import {TopNavSearch} from "@/components/TopNavSearch/TopNavSearch";

const App = () => {


    return (
        <>
            <Layout
                useRouting
                //languagesNoFlags
                topNavLogo={appLogo}
                searchRender={<TopNavSearch />}
                topNavLogoText={TemplateAppConstants.applicationName}
                leftNav={() => FenixAppController.getLeftNav()}
                footerName={TemplateAppConstants.companyName}
                languages={() => FenixAppController.languages()}
                footerRender={() => <Footer phoneNumber={FenixAppController.fenixSupportPhone}/>}
                footerLogo={companyLogo}
                onChangeLanguage={(sender, language) => FenixAppController.changeLanguage(language)}
                onLogoClick={() => FenixAppController.onLogoClickAsync()}
            />

        </>
    );
};

//Register initialize events
ch.registerInitializeCallback(async () => await FenixAppController.initializeAsync());
//Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await FenixAppController.authorizeAsync());

export default App;
