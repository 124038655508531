import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
	Button,
	ButtonType,
	Dropdown,
	DropdownAlign, Form, Icon, IconStyle,
	PageContainer,
	PageHeader, SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import ReactMarkdown from "react-markdown";
import styles from "./Search.module.scss";

import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";
import {ApiProvider, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {DynamicPageData} from "@/models/cms/DynamicPageResponse";
import SearchResponse from "@/models/server/responses/SearchResponse";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import {ISearchCategory} from "@/models/interfaces/ISearchCategory";
import {
	AppointmentBookingServicePointFilterOnChange,
	BookingFilter
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointFilter/AppointmentBookingServicePointFilter";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
import {IDoctor} from "@/models/interfaces/IDoctor";
import {SearchPageHit} from "@/components/SearchPageHit/SearchPageHit";
import {SearchCategoryFilter, SearchFilterOnChange} from "@/pages/Search/SearchCategoryFilter/SearchCategoryFilter";
import {Oval} from "react-loader-spinner";

interface ISearchProps {
}

export interface ISearchParams {
	query?: string | undefined;
	category?: string | undefined;
}

interface ISearchState {
	searchFilter: SearchFilter;

	orderedFilter: SearchOrderedDropDown[];
	data: SearchResponse | null;
	originalQuery: string;
	newQuery: string;
	offset: number;
	selectedFilterCategory: ISearchCategory | null;
	totalHits: number;
	initialCategory?: ISearchCategory;
	showLoadingSpinner: boolean;
	meiliSearchDown: boolean;
}

type SearchOrderedDropDown = "searchCategory";

export class SearchFilter {
	public filterFunctions = new Map<string, (items: any, filterBy: any) => any[]>();

	public dataSource = new Map<SearchOrderedDropDown, any[]>();

	public activeFilter = new Map<SearchOrderedDropDown, any>();

	addFilterFunction<T, Y>(item: SearchOrderedDropDown, filterBy: SearchOrderedDropDown, filter: (items: T[], filterBy: Y) => T[]) {
		this.filterFunctions.set([item, filterBy].join(), filter);
		return this;
	}
	getFilterFunction<T, Y>(item: SearchOrderedDropDown, filterBy: SearchOrderedDropDown): (items: T[], filterBy: Y) => T[] | null {
		return (this.filterFunctions.get([item, filterBy].join()) as (items: T[], filterBy: Y) => T[]) ?? null;
	}

	setSource<T>(item: SearchOrderedDropDown, items: T[]) {
		this.dataSource.set(item, items);
		return this;
	}

	setActiveFilter<T>(key: SearchOrderedDropDown, item: T | null | undefined) {
		if (item === null || item === undefined) {
			this.activeFilter.delete(key);
		} else {
			this.activeFilter.set(key, item);
		}
		return this;
	}

	getActiveFilter<T>(key: SearchOrderedDropDown): T | null {
		return this.activeFilter.get(key) ?? null;
	}

	getFilteredValues<T>(orderedFilterKeys: SearchOrderedDropDown[]) {
		const toFilterMap = new Map(this.dataSource);

		orderedFilterKeys.forEach((filterBy, filterByIndex) => {
			const activeFilterBy = this.activeFilter.get(filterBy);
			// @ts-ignore
			for (const [toFilterMapKey, toFilterMapValue] of toFilterMap.entries()) {
				const filterFunction = this.getFilterFunction(toFilterMapKey, filterBy);
				const filterFunctionOutput: unknown[] = filterFunction ? filterFunction(toFilterMapValue, activeFilterBy) ?? [] : [];

				const shouldSkip: boolean = (() => {
					const toFilterMapKeyIndex = orderedFilterKeys.indexOf(toFilterMapKey);
					const sameKey = toFilterMapKey === filterBy;
					const isFiltered = toFilterMapKeyIndex !== -1;
					const higherOrder = toFilterMapKeyIndex < filterByIndex;
					return sameKey || (isFiltered && higherOrder);
				})();

				if (!shouldSkip) {
					toFilterMap.set(toFilterMapKey, filterFunctionOutput);
				}
			}
		});
		return toFilterMap;
	}

	getFilteredValuesFor<T>(orderedFilterKeys: SearchOrderedDropDown[], key: SearchOrderedDropDown): T[] | null {
		const output = this.getFilteredValues(orderedFilterKeys);
		return output.get(key) ?? null;
	}
}







export default class Search extends AnonymousPage<ISearchProps, ISearchState> {

	categoryList: ISearchCategory[] = [
		{
			id: "service",
			name: Localizer.topNavServices
		},
		{
			id: "medical-field",
			name: Localizer.searchPagemedicalFields
		},
		{
			id: "clinic",
			name: Localizer.topNavClinics
		},
		{
			id: 'service-voucher',
			name: Localizer.searchPagevouchers
		},
		{
			id: "doctor",
			name: Localizer.topNavDoctors
		},
		{
			id: "location",
			name: Localizer.homePageHeaderLocation
		},
		{
			id: 'blog-post',
			name: Localizer.blogPageTitle
		},
		{
			id: 'occupational-health',
			name: Localizer.occupationalHealthPageTitle
		},
		{
			id: 'job-posting',
			name: Localizer.topNavJobs
		}
	]
	state: ISearchState = {
		searchFilter: new SearchFilter()
			.setSource("searchCategory", this.categoryList),
		orderedFilter: (() => {
			const list: SearchOrderedDropDown[] = [];
			
			return list;
		})(),
		data: null,
		originalQuery: '',
		newQuery: '',
		offset: 0,
		selectedFilterCategory: null,
		totalHits: 0,
		initialCategory: undefined,
		showLoadingSpinner: false,
		meiliSearchDown: false
	};

	public topNavRef: React.RefObject<any> = React.createRef();

	async initializeAsync(): Promise<void> {
		await super.initializeAsync();
		window.scrollTo(0,0);
		const params = this.parameters as ISearchParams as ISearchParams | null;
		if(params?.query){
			await this.setState({...this.state, showLoadingSpinner: true});
			if(params.category){
				const care: ISearchCategory | null = this.categoryList.filter((item) => item.id === params.category)[0];
				await this.setState({...this.state, initialCategory: care})
			}
			const response: SearchResponse = await this.getAsync(
				`/api/Application/Search?query=${params.query}${params.category ? `&filterCategory=${params.category}`: ''}&limit=500`
			);
			
			if(response.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else{
				await this.setState({...this.state, data: response, newQuery: params.query, originalQuery: params.query, totalHits: response.totalHits, showLoadingSpinner: false});
			}
			
		}
		else{
			await this.setState({data: null, newQuery: '', originalQuery: ''});
		}
	}

	public nextPage = async (query: string) => {
		await this.setState({...this.state, offset: this.state.offset + 4, showLoadingSpinner: true})
		const params = this.parameters as ISearchParams as ISearchParams | null;
		if(params?.query && !this.state.newQuery){
			query = params.query;
		}
		if(query && this.state.selectedFilterCategory){
			const searchResults: SearchResponse = await this.getAsync(`/api/Application/Search?query=${query}&offset=${this.state.offset}&limit=500&filterCategory=${this.state.selectedFilterCategory.id}`);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else{
				await this.setState({...this.state, data: searchResults, showLoadingSpinner: false});
			}
			
		}
		else if(query){
			const searchResults: SearchResponse = await this.getAsync(`/api/Application/Search?query=${query}&offset=${this.state.offset}&limit=500`);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else {
				await this.setState({...this.state, data: searchResults, showLoadingSpinner: false});
			}
		}
		this.scrollToTop();
	}
	public previousPage = async (query: string) => {
		await this.setState({...this.state, offset: this.state.offset - 4, showLoadingSpinner: true})
		const params = this.parameters as ISearchParams as ISearchParams | null;
		if(params?.query && !this.state.newQuery){
			query = params.query;
		}
		if(query && this.state.selectedFilterCategory){
			const searchResults: SearchResponse = await this.getAsync(`/api/Application/Search?query=${query}&offset=${this.state.offset}&limit=500&filterCategory=${this.state.selectedFilterCategory.id}`);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else {
				await this.setState({...this.state, data: searchResults, showLoadingSpinner: false});
			}
		}
		else if(query){
			const searchResults: SearchResponse = await this.getAsync(`/api/Application/Search?query=${query}&offset=${this.state.offset}&limit=500`);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else {
				await this.setState({...this.state, data: searchResults, showLoadingSpinner: false});
			}
		}
		this.scrollToTop();
	}
	
	public isButtonDisabled = (buttonType: string) => {
		switch(buttonType){
			case 'previous':
				return (!this.state.data || this.state.data.offset == 0)
			case 'next':
				return ((!this.state.data) || (this.state.data.offset > this.state.totalHits) || (this.state.data.offset + 4 >= this.state.totalHits)  )
			default:
				return true;
		}
		
	}

	public searchFromStrapi = async (query: string) => {
		if(query && this.state.selectedFilterCategory){
			await this.setState({...this.state, showLoadingSpinner: true})
			const searchResults: SearchResponse = await ApiProvider.getAsync(`/api/Application/Search?query=${query}&limit=500&filterCategory=${this.state.selectedFilterCategory.id}`, null);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else {
				await this.setState({
					...this.state,
					data: searchResults,
					originalQuery: query,
					newQuery: query,
					totalHits: searchResults.totalHits,
					showLoadingSpinner: false
				})
			}
		}
		else if(query){
			await this.setState({...this.state, showLoadingSpinner: true})
			const searchResults: SearchResponse = await ApiProvider.getAsync(`/api/Application/Search?query=${query}&limit=500`, null);
			if(searchResults.failed){
				await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
			}
			else {
				await this.setState({
					...this.state,
					data: searchResults,
					originalQuery: query,
					newQuery: query,
					totalHits: searchResults.totalHits,
					showLoadingSpinner: false
				})
			}
		}
	}
	
	private async fetchData (filterValue: SearchFilterOnChange | null, query: string) {
		if(query){
			if (filterValue?.searchCategory?.id){
				await this.setState({...this.state, showLoadingSpinner: true})
				const searchResults: SearchResponse = await ApiProvider.getAsync(`/api/Application/Search?query=${query}&limit=500&filterCategory=${filterValue?.searchCategory?.id}`, null);
				if(searchResults.failed){
					await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
				}
				else {
					await this.setState({
						...this.state,
						data: searchResults,
						originalQuery: query,
						newQuery: query,
						selectedFilterCategory: filterValue?.searchCategory,
						totalHits: searchResults.totalHits,
						showLoadingSpinner: false
					})
				}
			}
			else{
				await this.setState({...this.state, showLoadingSpinner: true})
				const searchResults: SearchResponse = await ApiProvider.getAsync(`/api/Application/Search?query=${query}&limit=500`, null);
				if(searchResults.failed){
					await this.setState({...this.state, meiliSearchDown: true, showLoadingSpinner: false});
				}
				else {
					await this.setState({
						...this.state,
						data: searchResults,
						originalQuery: query,
						newQuery: query,
						selectedFilterCategory: null,
						totalHits: searchResults.totalHits,
						showLoadingSpinner: false
					})
				}
			}
		}
		else{
			if(filterValue?.searchCategory?.id){
				await this.setState({...this.state, selectedFilterCategory: filterValue?.searchCategory})
			}
		}
		
	}
	
	public scrollToTop = () => {
		if(window.matchMedia('(max-width: 768px)').matches){
			const element = document.getElementById('topNavDiv');
			if(element){
				const offset = 80;
				const bodyRect = document.body.getBoundingClientRect().top;
				const elementRect = element.getBoundingClientRect().top;
				const elementPosition = elementRect - bodyRect;
				const offsetPosition = elementPosition - offset;
				window.scrollTo({
					top: offsetPosition,
					behavior: 'auto'
				});
			}
		}
	};
	

	public render(): React.ReactNode {

		return (
			<PageContainer fullWidth className={styles.pageContainer}>
				<PageHeader className={styles.pageHeader} title={Localizer.searchTitle}/>
				<div className={styles.pageContent}>
					<div className={styles.fluidContent}>
							<>
								<div className={styles.searchContainer}>
									<div className={styles.searchBarDiv}>
										<input type={"text"} className={styles.searchInput} value={this.state.newQuery} onChange={(event) => this.setState({...this.state, newQuery: event.target.value})} />
										<Button
											className={[styles.inputsContainerActionButton, styles.actionButton].join(" ")}
											onClick={async () => this.searchFromStrapi(this.state.newQuery)}
											label={Localizer.genericSearch}
										/>
									</div>
									<div className={styles.filterBarDiv} ref={this.topNavRef}>
										<div className={styles.filterDiv}>
											<SearchCategoryFilter initialCategory={this.state.initialCategory} onChange={async (filterValue) => this.fetchData(filterValue, this.state.newQuery)} />
										</div>
										<div className={styles.paginationDiv} id='topNavDiv'>
											<button className={styles.paginationButton} disabled={this.isButtonDisabled('previous')} onClick={() => this.previousPage(this.state.newQuery)}> <Icon name={"chevron-left"}	style={IconStyle.Solid}	/> </button>
											<button className={styles.paginationButton} disabled={this.isButtonDisabled('next')} onClick={() => this.nextPage(this.state.newQuery)}> <Icon name={"chevron-right"}	style={IconStyle.Solid}	/></button>
										</div>
									</div>
									<div className={styles.searchResultsContainer}>
										<div className={styles.searchResultsHeader}>
											{this.state.data &&
                          <h4>{Localizer.searchPagefound} {this.state.totalHits ?? 0} {Localizer.searchPagehits}</h4>
											}
										</div>
										
												<div className={styles.searchResultsHitsContainer}>
													{this.state.showLoadingSpinner &&
                              <div className={styles.loadingContainer}>
                                  <Oval
                                      visible={true}
                                      height="50"
                                      width="50"
                                      color="#F28325"
                                      secondaryColor="#a95c1a"
                                      ariaLabel="oval-loading"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                  />
                              </div>
													}
													{this.state.meiliSearchDown &&
															<div className={styles.loadingContainer}>
																	<span>{Localizer.searchPageserviceError}</span>
															</div>}
													{this.state.data &&
															<>
													<div className={styles.searchResultsHits}>
														{this.state.data.hits.map(hit => {
															return(
																<SearchPageHit key={hit.id} hit={hit} query={this.state.originalQuery} />
															)
														})}
													</div>
													<div className={styles.bottomPaginationDiv}>
															<button className={styles.paginationButton} disabled={this.isButtonDisabled('previous')} onClick={() => {this.previousPage(this.state.newQuery)}}> <Icon name={"chevron-left"}	style={IconStyle.Solid}	/> </button>
															<button className={styles.paginationButton} disabled={this.isButtonDisabled('next')} onClick={() => {this.nextPage(this.state.newQuery)}}> <Icon name={"chevron-right"}	style={IconStyle.Solid}	/></button>
													</div>
                              </>
													}
												</div>
										
									</div>
									
								</div>
							</>
					</div>
				</div>
			</PageContainer>
		);
	}
}
